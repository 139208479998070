import React, { useState, useEffect } from 'react'
import FooterPages from './FooterPages'
import Select from "react-select";
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import UserService from '../services/UserService'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import getNotification from '../Redux/Action/action'
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import { toWords } from 'number-to-words';

const { REACT_APP_UPLOAD_DATA } = process.env
const { REACT_APP_REDIRECT_URI } = process.env
const { REACT_APP_ADMIN_USER } = process.env
const APPROVER_USERS = JSON.parse(process.env.REACT_APP_APPROVER_USERS || '[]');
const { REACT_APP_RECRUITMENT_HEAD } = process.env
function Dashboard() {
  const navigate = useNavigate()
  const location = useLocation()
  const [filterstatus, setfilterstatus] = useState("Select")
  const [jobpostingfilteredlist, setjobpostingfilteredlist] = useState([])
  const [skillsetlist, setskillsetlist] = useState([])
  const [technicalskillsoptions, settechnicalskillsoptions] = useState([])
  const [soft_skillsoptions, setsoft_skillsoptions] = useState([])
  const [newjobcreationscreen, setnewjobcreationscreen] = useState(false)
  const [newjobcreationvalue, setnewjobcreationvalue] = useState({})
  const [clientcity, setclientcity] = useState("");
  const [startdatestate, setstartdatestate] = useState("");
  const [enddatestate, setenddatestate] = useState("");
  const [statusfilter, setstatusfilter] = useState("");
  const [createdbyfilter, setcreatedbyfilter] = useState("");
  const [myreportingmanager, setmyreportingmanager] = useState("")
  const [jobpostinglist, setjobpostinglist] = useState([])
  const [employeelist, setemployeelist] = useState([])
  const [fullemployeelist, setfullemployeelist] = useState([])
  const [job_positionlist, setjob_positionlist] = useState([])
  const [clientlist, setclientlist] = useState([])
  const [viewobject, setviewobject] = useState({});
  const [editobject, seteditobject] = useState({})
  const [editscreen, seteditscreen] = useState(false)
  const [reportingmanagerlist, setreportingmanagerlist] = useState([])
  const [verticalHeadList, setVerticalHeadList] = useState([])
  const [recruitmentHeadList, setRecruitmentHeadList] = useState([])
  const [recruitmentAssigneeList, setRecruitmentAssigneeList] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showHoldRemarkInput, setShowHoldRemarkInput] = useState(false)
  const [showRejectRemarkInput, setShowRejectRemarkInput] = useState(false)
  const [actionRemarks, setActionRemarks] = useState('')
  const [actionJobID, setActionJobID] = useState()
  const [selectedAssignee, setSelectedAssignee] = useState('');
  const [showHoldRemarkScreen, setShowHoldRemarkScreen] = useState()
  const [showRejectRemarkScreen, setShowRejectRemarkScreen] = useState()
  const [showHoldModal, setShowHoldModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [specificJobDetail, setSpaecifiJobDetail] = useState() // This is used to show the job detail when assignee is goong to be assigned
  const [verticallist, setverticallist] = useState([
    "Technology", "CPG", "Pre-Sales", "HR", "Leadership", "Product", "Retail", "Retail-Sales", "Marketing", "Partnership", "CPG - Sales", "L & D", "Admin", "IT"
  ])
  const [designationlist, setdesignationlist] = useState([
    "Intern", "Consultant", "Senior Consultant", "Associate Manager", "Manager", "Senior Manager", "AVP-Assistant Vice President", "VP-Vice President", "SVP-Senior Vice President", "CXO"
  ])
  const [previewscreen, setpreviewscreen] = useState(false)
  const dispatch = useDispatch()
  const initialnewjobcreationvalue = {
    designation: "Select",
    title: "",
    job_position: "Select",
    jobtitle: "",
    mandatory_skillset: [],
    good_to_have_skillset: [],
    soft_skills: [],
    relevant_experience_min: 0,
    relevant_experience_max: 15,
    summary: "",
    job_type: "Full Time",
    reason_for_recruitment: "Fresh",
    notice_period_days: "Select",
    preferred_working_location: "Select",
    preferred_working_locationoth: "",
    budget_min_range: "",
    budget_max_range: "",
    customer: "Select",
    customer_type: "Select",
    no_of_positions: 0,
    panel_list: [],
    remarks: "",
    vertical_head_id: "Select",
    assigned_to: ""
    // Add Slider values as well if applicable
  };
  const [invalidMin, setInvalidMin] = useState(false);  // Track invalid min budget
  const [invalidMax, setInvalidMax] = useState(false);  // Track invalid max budget

  const handleMinChange = (e) => {
    const minBudget = e.target.value;

    // Check if min budget is valid
    if (!isNaN(minBudget) && minBudget.trim() !== '') {
      setInvalidMin(false);  // Reset invalid flag if valid
      setnewjobcreationvalue((prevState) => ({
        ...prevState,
        budget_min_range: minBudget,
      }));
    } else {
      setInvalidMin(true);  // Set invalid flag if not valid
    }
  };

  const handleMaxChange = (e) => {
    const maxBudget = e.target.value;

    // Check if max budget is valid
    if (!isNaN(maxBudget) && maxBudget.trim() !== '') {
      setInvalidMax(false);  // Reset invalid flag if valid
      setnewjobcreationvalue((prevState) => ({
        ...prevState,
        budget_max_range: maxBudget,
      }));
    } else {
      setInvalidMax(true);  // Set invalid flag if not valid
    }
  };

  // Function to count words
  const countWords = (text) => {
    if (text) {
      return text.trim().split(/\s+/).length;
    }
    else {
      return 0
    }
  };

  // Function to handle text change and validate word count
  const handleSummaryChange = (e) => {
    const newSummary = e.target.value;
    setnewjobcreationvalue({
      ...newjobcreationvalue,
      summary: newSummary
    });
  };

  const wordCount = countWords(newjobcreationvalue?.summary); // Use to count the number of words in summary
  const minBudgetInWords = !invalidMin && newjobcreationvalue.budget_min_range ? toWords(newjobcreationvalue.budget_min_range) : '';
  const maxBudgetInWords = !invalidMax && newjobcreationvalue.budget_max_range ? toWords(newjobcreationvalue.budget_max_range) : '';

  // Fetching ALl the Vertical Heads
  const fetchingVerticalHeads = async () => {
    if (UserService.isLoggedIn()) {
      try {
        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/verticalheads`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },

        };
        const getResponse = await axios(config);
        if (getResponse.data !== "Invalid User!") {
          setVerticalHeadList(getResponse.data)
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/reporting_managers`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
        });
      }, 1000);
    }
  };

  // Fteching All recruiter Head
  const fetchingRecrutmentHeads = async () => {
    if (UserService.isLoggedIn()) {
      try {
        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/recruitmenthead`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },

        };
        const getResponse = await axios(config);
        // console.log(getResponse)
        if (getResponse.data !== "Invalid User!") {
          setRecruitmentHeadList(getResponse.data)
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/reporting_managers`,
          });
        }
      } catch (err) {
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
        });
      }, 1000);
    }
  };

  // Fetching All Recruitment Assignee
  const fetchingRecruitmentAssignee = async () => {
    if (UserService.isLoggedIn()) {
      try {
        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/recruitmentassignee`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },

        };
        const getResponse = await axios(config);
        if (getResponse.data !== "Invalid User!") {
          setRecruitmentAssigneeList(getResponse.data)
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/reporting_managers`,
          });
        }
      } catch (err) {
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
        });
      }, 1000);
    }
  }

  // This function is used to get the name of the created by from the all the vertical head login and hr admin login 
  const getNameofcreatedby = (id) => {
    if (id == 'hradmin') {
      return id
    }
    else {
      const foundHead = verticalHeadList.find((head) => head.vertical_head === id);
      return (foundHead?.emp_name)
    }
  }

  // this function is used to check that wheather the assigned_to is recruiter head or not
  // if yes then editable option is disable if no then it is enabled for Director and Vertical Head
  const checkRecruiterHead = (id) => {
    const foundHead = recruitmentHeadList.find((head) => head.recruitment_head_emp_id === id);
    if (foundHead === undefined) return false
    else return true
  }

  // Fecthing All the Employee
  const handlefetchemployees = async () => {
    if (UserService.isLoggedIn()) {
      try {
        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/employee`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
        };
        const getResponse = await axios(config);
        //console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {
          setfullemployeelist(getResponse?.data)
          setemployeelist(getResponse?.data?.map((it) => {
            return { label: it.emp_name, value: it.emp_name }
          }))

          setmyreportingmanager(getResponse?.data?.filter((it) => it.emp_id?.toLowerCase() === UserService.getUsername().toLowerCase())[0]?.reporting_manager)

          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/reporting_managers`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
        });
      }, 1000);
    }
  };

  const handlefiltervariables = (type, value) => {
    let filteredList = [...jobpostinglist]

    if (type === "status") {
      setstatusfilter(value)
    }
    else if (type === "created_by") {
      setcreatedbyfilter(value)
    }
    else if (type === "startdate") {
      setstartdatestate(value)
    }
    else if (type === "enddate") {
      setenddatestate(value)
    }

  }

  // Fecthing All the clients
  const handlefetchclientlist = async () => {
    if (UserService.isLoggedIn()) {
      try {

        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/getclient`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },

        };
        const getResponse = await axios(config);
        // console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {

          setclientlist(getResponse.data)

          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
        });
      }, 1000);
    }
  };

  const handlepreviewscreen = () => {
    if (parseInt(newjobcreationvalue.relevant_experience_min) > parseInt(newjobcreationvalue.relevant_experience_max)) {
      dispatch(
        getNotification({
          message: "Minimun Experience Can't be greater than Maximum Experience",
          type: "danger",
        })
      )
    }
    else if (parseInt(newjobcreationvalue.budget_min_range) > parseInt(newjobcreationvalue.budget_max_range)) {
      dispatch(
        getNotification({
          message: "Minimun Budget Can't be greater than Maximum Budget",
          type: "danger",
        })
      )
    }
    else if (newjobcreationvalue.designation && newjobcreationvalue.job_position
      && newjobcreationvalue.mandatory_skillset?.length > 0 &&
      newjobcreationvalue.good_to_have_skillset?.length > 0
      && newjobcreationvalue.soft_skills?.length > 0
      && newjobcreationvalue.relevant_experience_min && newjobcreationvalue.relevant_experience_max &&
      newjobcreationvalue.summary &&
      newjobcreationvalue.job_type && newjobcreationvalue.reason_for_recruitment
      && newjobcreationvalue.notice_period_days && newjobcreationvalue.notice_period_days !== "Select"
      && newjobcreationvalue.preferred_working_location && newjobcreationvalue.budget_min_range && newjobcreationvalue.budget_max_range
      && newjobcreationvalue.customer && newjobcreationvalue.customer !== "Select" &&
      newjobcreationvalue.customer_type && newjobcreationvalue.customer_type !== "Select" &&
      newjobcreationvalue.no_of_positions
      && newjobcreationvalue.vertical_head_id
    ) {
      if (newjobcreationvalue.job_position === "Others") {
        if (newjobcreationvalue.jobtitle && newjobcreationvalue.jobtitle.trim() !== "") {
          setpreviewscreen(true);
        } else {
          dispatch(getNotification({
            message: "Please fill all entries",
            type: "default",
          }));
        }
      }
      else {
        setpreviewscreen(true);
      }
    }
    else {
      setpreviewscreen(false)
      dispatch(getNotification({
        message: "Please fill all entries",
        type: "warning",
      }))
    }
  }

  const handlefetchjob_positions = async () => {
    if (UserService.isLoggedIn()) {
      try {
        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/job_position_master`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },


        };
        const getResponse = await axios(config);
        if (getResponse.data !== "Invalid User!") {
          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
          setjob_positionlist(getResponse.data)
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
        });
      }, 1000);
    }
  };

  const formatdate = (date) => {
    if (date === "open" || date === "Open" || typeof (date) !== "string" || date === null) { return "Open" }
    else {
      const onlydate = String(date.split(" ")[0])


      return `${onlydate.split("-")[2]}-${onlydate.split("-")[1]}-${onlydate.split("-")[0]}`
    }
  }

  const capitalizefirstleter = (str) => {
    if (str?.length === 0 || str === null) return "Open"; // Check if the string is empty
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  // Fetching ALl the Skills
  const handlefetchskills = async () => {
    if (UserService.isLoggedIn()) {
      try {

        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/skills`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },

        };
        const getResponse = await axios(config);
        // console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {
          let arrt = []
          let arrs = []
          {
            getResponse.data?.filter((it) => it.skill_category === "technical").map((item) => {
              item.skill.split(",")?.map((it, index) => {
                arrt.push({ label: it, value: it })
              })

            })
          }
          {
            getResponse.data?.filter((it) => it.skill_category === "soft").map((item) => {
              item.skill.split(",")?.map((it, index) => {
                arrs.push({ label: it, value: it })
              })

            })
          }

          settechnicalskillsoptions(arrt)
          setsoft_skillsoptions(arrs)

          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/job_positionmaster`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/job_positionmaster`,
        });
      }, 1000);
    }
  };

  // Fetching All the Job Posting
  const handlefetch = async () => {
    if (UserService.isLoggedIn()) {
      try {
        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/job_postings`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
            loggedID: `${UserService.getUsername()}`
          }
        };
        const getResponse = await axios(config);
        if (getResponse.data !== "Invalid User!") {
          setnewjobcreationvalue(initialnewjobcreationvalue)
          // if ((UserService.getUsername() === REACT_APP_ADMIN_USER)) 
          // {
          setjobpostinglist(getResponse.data.sort((a, b) => new Date(b.date_created) - new Date(a.date_created)))
          setjobpostingfilteredlist(getResponse.data.sort((a, b) => new Date(b.date_created) - new Date(a.date_created)))
          // }
          // else {
          //   setjobpostinglist(getResponse.data.filter((it) => it.created_by === UserService.getUsername() ||
          //     it.assigned_to === UserService.getUsername()).sort((a, b) => new Date(b.date_created) - new Date(a.date_created)))
          //   setjobpostingfilteredlist(getResponse.data.filter((it) => it.created_by === UserService.getUsername() ||
          //     it.assigned_to === UserService.getUsername()).sort((a, b) => new Date(b.date_created) - new Date(a.date_created)))
          // }
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
        });
      }, 1000);
    }
  };

  // Adding Job Posting to the Database
  const handleadd = async () => {
    if (UserService.isLoggedIn()) {
      setIsSubmitting(true)
      try {
        const FormData = require("form-data");
        const sendData = new FormData();
        sendData.append("job_position", newjobcreationvalue.job_position === "Others" ? `Others ${newjobcreationvalue.jobtitle}` : newjobcreationvalue.job_position);
        sendData.append("mandatory_skillset", newjobcreationvalue.mandatory_skillset.join(","));
        sendData.append("good_to_have_skillset", newjobcreationvalue.good_to_have_skillset.join(","));
        sendData.append("soft_skills", newjobcreationvalue.soft_skills.join(","));
        sendData.append("panel_list", newjobcreationvalue.panel_list.join(","));
        sendData.append("relevant_experience_min", Number(newjobcreationvalue.relevant_experience_min))
        sendData.append("relevant_experience_max", Number(newjobcreationvalue.relevant_experience_max))
        sendData.append("notice_period_days", newjobcreationvalue.notice_period_days)
        sendData.append("job_type", newjobcreationvalue.job_type)
        sendData.append("reason_for_recruitment", newjobcreationvalue.reason_for_recruitment)
        newjobcreationvalue.preferred_working_location === "Client" ? sendData.append("preferred_working_location", `Client ${newjobcreationvalue.preferred_working_locationoth}`) : sendData.append("preferred_working_location", newjobcreationvalue.preferred_working_location)
        sendData.append("budget_min_range", newjobcreationvalue.budget_min_range)
        sendData.append("budget_max_range", newjobcreationvalue.budget_max_range)
        sendData.append("customer_type", newjobcreationvalue.customer_type)
        sendData.append("customer", newjobcreationvalue.customer)
        sendData.append("no_of_positions", newjobcreationvalue.no_of_positions)
        sendData.append("date_closed", "")
        sendData.append("status", 1)
        sendData.append("designation", newjobcreationvalue.designation)
        sendData.append("summary", newjobcreationvalue.summary)
        sendData.append("remarks", newjobcreationvalue.remarks === '' ? 'No Remark Is Given' : newjobcreationvalue.remarks);
        sendData.append("vertical_head_id", newjobcreationvalue.vertical_head_id);

        const config = {
          method: "post",
          url: `${REACT_APP_UPLOAD_DATA}/job_posting`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
            loggedID: `${UserService.getUsername()}`
          },
          data: sendData,
        };
        const getResponse = await axios(config);
        if (getResponse.data !== "Invalid User!") {
          setnewjobcreationscreen(false)
          handlefetch()
          setIsSubmitting(false)
          document.getElementById("closemodal").click()
          dispatch(
            getNotification({
              message: "Job Posting generated successfully",
              type: "success",
            })
          );
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    }
    else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
        });
      }, 1000);
    }
  };
  // Handle Job Posting Edit
  const handleedit = async () => {
    if (UserService.isLoggedIn()) {
      console.log(editobject)
      setIsSubmitting(true)
      try {
        const FormData = require("form-data");
        const sendData = new FormData();
        if (editobject.relevant_experience_min > editobject.relevant_experience_max) {
          toast.warning("Minimum experience should not be greater than maximum Experience")
        }
        else if (editobject.budget_min_range > editobject.budget_max_range) {
          toast.warning("Minimum Budget should not be greater than maximum Budget")
        }
        else {
          sendData.append("job_position", editobject.job_position === "Others" ? `Others ${editobject.jobtitle}` : editobject.job_position);
          sendData.append("mandatory_skillset", typeof (editobject.mandatory_skillset === "string") ? editobject.mandatory_skillset : editobject.mandatory_skillset.join(","));
          sendData.append("good_to_have_skillset", typeof (editobject.good_to_have_skillset) === "string" ? editobject.good_to_have_skillset : editobject.good_to_have_skillset.join(","));
          sendData.append("soft_skills", typeof (editobject.soft_skills) === "string" ? editobject.soft_skills : editobject.soft_skills.join(","));
          sendData.append("panel_list", typeof (editobject.panel_list) ? editobject.panel_list : editobject.panel_list.join(","));
          sendData.append("relevant_experience_min", Number(editobject.relevant_experience_min))
          sendData.append("relevant_experience_max", Number(editobject.relevant_experience_max))
          sendData.append("notice_period_days", editobject.notice_period_days)
          sendData.append("job_type", editobject.job_type)
          sendData.append("reason_for_recruitment", editobject.reason_for_recruitment)
          editobject.preferred_working_location === "Client" ? sendData.append("preferred_working_location", `Client ${editobject.preferred_working_locationoth}`) : sendData.append("preferred_working_location", editobject.preferred_working_location)
          sendData.append("budget_min_range", editobject.budget_min_range)
          sendData.append("budget_max_range", editobject.budget_max_range)
          sendData.append("customer_type", editobject.customer)
          sendData.append("customer", editobject.customer_type)
          sendData.append("no_of_positions", editobject.no_of_positions)
          sendData.append("date_closed", "")
          sendData.append("designation", editobject.designation)
          sendData.append("summary", editobject.summary)
          sendData.append("created_by", UserService.getUsername());
          sendData.append("remarks", editobject.remarks)
          sendData.append("vertical_head_id", editobject.vertical_head_id)
          if (editobject.current_status === 3.2 || editobject.current_status === 3.3) {
            sendData.append("current_status", 2.0)
          }
          //console.log(sendData)
          const config = {
            method: "put",
            url: `${REACT_APP_UPLOAD_DATA}/job_posting/${editobject.job_id}`,
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
            data: sendData,
          };
          const getResponse = await axios(config);
          console.log(getResponse);
          if (getResponse.data !== "Invalid User!") {
            setnewjobcreationvalue(initialnewjobcreationvalue)
            setnewjobcreationscreen(false)
            setIsSubmitting(false)
            document.getElementById("closemodal3").click()
            dispatch(
              getNotification({
                message: "Job Posting edited successfully",
                type: "success",
              })
            );
            handlefetch()
          }
          else {
            UserService.doLogin({
              redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
            });
          }
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    }
    else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
        });
      }, 1000);
    }
  };
  // handle Job Posting Status
  const changeJobStatus = async (job_id, current_status, remark) => {
    if (UserService.isLoggedIn()) {
      setIsSubmitting(true)
      try {
        const config = {
          method: "put",
          url: `${REACT_APP_UPLOAD_DATA}/job_posting/status/${job_id}`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
          data: {
            current_status: current_status,
            action_taker_id: UserService.getUsername(),
            remarks: remark
          },
        };
        const getResponse = await axios(config)
        if (getResponse.data !== "Invalid User!") {
          if (current_status > 2) {
            if (current_status === 4.0) {
              setShowAssignModal(false)
            }
            else if (current_status === 3.2) {
              setShowHoldModal(false)
            }
            else {
              setShowRejectModal(false)
            }
          }
          dispatch(
            getNotification({
              message: "Job Posting Proceeded Successfully",
              type: "success",
            })
          );
          handlefetch()
          setIsSubmitting(false)
          setActionJobID('')
          setSelectedAssignee('')

        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
          });
        }
      }
      catch (err) {
        setIsSubmitting(false)
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    }
    else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
        });
      }, 1000);
    }
  }

  useEffect(() => {
    handlefetch()
    handlefetchskills()
    handlefetchjob_positions()
    handlefetchclientlist()
    handlefetchemployees()
    fetchingVerticalHeads()
    fetchingRecrutmentHeads()
    fetchingRecruitmentAssignee()
  }, [])

  return (
    <>
      {/*Preview Screen */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: "#fafafa" }}>
              <h6 className="modal-title orangetheme" id="exampleModalLabel">
                Preview
              </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closemodal"
                onClick={() => setpreviewscreen(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body p-4">
              <div className='my-2'>
                <h5 className='text-danger text-sm'>Details entered mentioned below</h5>
                <span className='row p-3'>
                  <table className="table table-sm table-striped">
                    <tbody>
                      <tr>
                        <th scope="row">Designation<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.designation}</td>
                      </tr>
                      <tr>
                        <th scope="row">Job Title<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.job_position === "Others" ? `Others ${newjobcreationvalue.jobtitle}` : newjobcreationvalue.job_position}</td>
                      </tr>

                      <tr>
                        <th scope="row">Mandatory Skills<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.mandatory_skillset?.join(",")}</td>
                      </tr>
                      <tr>
                        <th scope="row">Good to Have Skills<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.good_to_have_skillset?.join(",")}</td>
                      </tr>
                      <tr>
                        <th scope="row">Soft Skills<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue?.soft_skills}</td>
                      </tr>
                      <tr>
                        <th scope="row">Summary<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue?.summary}</td>
                      </tr>
                      <tr>
                        <th scope="row">Experience(in years)<span className="text-danger">*</span> </th>
                        <td> {newjobcreationvalue.relevant_experience_min}-{newjobcreationvalue.relevant_experience_max}</td>
                      </tr>
                      <tr>
                        <th scope="row">Notice Period (in days)<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.notice_period_days}</td>
                      </tr>
                      <tr>
                        <th scope="row">Job Type<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.job_type}</td>
                      </tr>
                      <tr>
                        <th scope="row">Reason of Recruitment<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.reason_for_recruitment}</td>
                      </tr>
                      <tr>
                        <th scope="row">Preferred Working Location<span className="text-danger">*</span></th>
                        <td>
                          {newjobcreationvalue.preferred_working_location === "Client" ? `${newjobcreationvalue.preferred_working_location} ${newjobcreationvalue.preferred_working_locationoth}` : newjobcreationvalue.preferred_working_location}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Budget Band(in lacs)<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.budget_min_range}-{newjobcreationvalue.budget_max_range}</td>
                      </tr>
                      <tr>
                        <th scope="row">Customer<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.customer_type}</td>
                      </tr>
                      <tr>
                        <th scope="row">Customer Name<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.customer}</td>
                      </tr>
                      <tr>
                        <th scope="row">No. of Positions<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.no_of_positions}</td>
                      </tr>
                      <tr>
                        <th scope="row">Panelist </th>
                        <td>{newjobcreationvalue?.panel_list?.join(",")}</td>
                      </tr>
                      <tr>
                        <th scope="row">Vertical Head<span className="text-danger">*</span></th>
                        <td>{
                          verticalHeadList.map(item => {
                            if (item.vertical_head === newjobcreationvalue.vertical_head_id) {
                              return ` ${item.emp_name} (${item.vertical_head})`
                            }
                          })
                        }</td>
                      </tr>
                      <tr>
                        <th scope="row">Remarks<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.remarks === '' ? 'No Remark Is Given' : newjobcreationvalue.remarks}</td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                <h6>{isSubmitting ? <span className='text-success'>Job Posting Is Adding... Wait....</span> : <span className='text-danger'>Please check before submitting!!</span>}</h6>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-dismiss="modal"
                onClick={() => setpreviewscreen(false)}
                disabled={isSubmitting ? true : false}
              >
                Continue Editing
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  handleadd()
                }}
                disabled={isSubmitting ? true : false}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* View Job Posting */}
      <div
        class="modal fade"
        id="exampleModal2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModal2Label"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div class="modal-header" style={{ backgroundColor: "#fafafa" }}>
              <h6 class="modal-title orangetheme" id="exampleModal2Label">
                View Job Posting
              </h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closemodal2"

              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-4">


              <div className='my-2'>
                Details below:
                <span className='row p-3'>

                  <table className="table table-sm table-striped ">

                    <tbody>
                      <tr>
                        <th scope="row">Designation</th>
                        <td>{viewobject.designation}</td>
                      </tr>
                      <tr>
                        <th scope="row">Job Title</th>
                        <td>{viewobject.job_position}</td>
                      </tr>

                      <tr>
                        <th scope="row">Mandatory Skills</th>
                        <td>{viewobject.mandatory_skillset}</td>
                      </tr>
                      <tr>
                        <th scope="row">Good to Have Skills</th>
                        <td>{viewobject.mandatory_skillset}</td>
                      </tr>
                      <tr>
                        <th scope="row">Soft Skills</th>
                        <td>{viewobject.soft_skills}</td>
                      </tr>
                      <tr>
                        <th scope="row">Summary</th>
                        <td>{viewobject.summary}</td>
                      </tr>
                      <tr>
                        <th scope="row">Experience(in years) </th>
                        <td> {viewobject.relevant_experience_min}-{viewobject.relevant_experience_max}</td>
                      </tr>
                      <tr>
                        <th scope="row">Notice Period (in days)</th>
                        <td>{viewobject.notice_period_days}</td>
                      </tr>
                      <tr>
                        <th scope="row">Job Type</th>
                        <td>{viewobject.job_type}</td>
                      </tr>
                      <tr>
                        <th scope="row">Reason of Recruitment</th>
                        <td>{viewobject.reason_for_recruitment}</td>
                      </tr>
                      <tr>
                        <th scope="row">Preferred Working Location</th>
                        <td>
                          {viewobject.preferred_working_location}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Budget Band(in lacs)</th>
                        <td>{viewobject.budget_min_range}-{viewobject.budget_max_range}</td>
                      </tr>
                      <tr>
                        <th scope="row">Customer</th>
                        <td>{viewobject.customer_type}</td>
                      </tr>
                      <tr>
                        <th scope="row">Customer Name</th>
                        <td>{viewobject.customer}</td>
                      </tr>
                      <tr>
                        <th scope="row">No. of Positions</th>
                        <td>{viewobject.no_of_positions}</td>
                      </tr>
                      <tr>
                        <th scope="row">Panelist</th>
                        <td>{viewobject?.panel_list}</td>
                      </tr>
                      <tr>
                        <th scope="row">Approved By : </th>
                        <td>{
                          verticalHeadList.map(item => {
                            if (item.vertical_head === viewobject.vertical_head_id) {
                              return ` ${item.emp_name} (${item.vertical_head})`
                            }
                          })
                        }</td>
                        {/* <td>{reportingmanagerlist?.filter((it) => it.rm_id === viewobject.rm_id)[0]?.rm_name}</td> */}
                      </tr>
                      <tr>
                        <th scope="row">Remarks</th>
                        <td>{viewobject.remarks}</td>
                      </tr>
                    </tbody>
                  </table>
                </span>  </div>
            </div>

          </div>
        </div>
      </div>
      {/* Edit Job Posting */}
      <div
        className="modal fade"
        id="exampleModal3"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModal3Label"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: "#fafafa" }}>
              <h6 className="modal-title orangetheme" id="exampleModal3Label">
                Edit Job Posting
              </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closemodal3"
                onClick={() => seteditscreen(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body p-4">
              <div className='my-2'>
                Details entered mentioned below:
                <ol type="1" className='my-3'>
                  <li className='mb-3'>
                    <div>Job ID</div>
                    <input className="form-control my-2" disabled placeholder='Job ID' value={editobject.job_id} />
                  </li>
                  <li className='mb-3'>
                    <div>Designation<span className="text-danger">*</span></div>
                    <select className='my-2 form-select' value={editobject.designation} onChange={(e) => seteditobject({ ...editobject, designation: e.target.value })}>
                      <option>Select</option>
                      {designationlist?.map((item) => {
                        return <option>{item}</option>
                      })}
                    </select>
                  </li>
                  <li className='mb-3'>
                    <div>Job Title<span className="text-danger">*</span></div>
                    <div className='row'>
                      <select className='my-2 form-select col-sm mx-2' value={editobject.job_position?.includes("Others") ? editobject.job_position?.split(" ")[0] : editobject.job_position} onChange={(e) => seteditobject({ ...editobject, job_position: e.target.value })}>
                        <option>Select</option>
                        {job_positionlist?.map((item) => {
                          return <option>{item.job_title}</option>
                        })}
                        <option>Others</option>
                      </select>
                      {editobject.job_position?.includes("Others") &&
                        <input className="form-control my-2 col-sm mx-1" placeholder='New Job Title ' value={editobject.jobtitle} onChange={(e) => seteditobject({ ...editobject, jobtitle: e.target.value, job_position: "Others" })} />
                      }
                    </div>
                  </li>
                  <li className='my-3'>
                    Skills
                    <ol type="a">
                      <li className='my-3'>
                        <div>Mandatory Skillset<span className="text-danger">*</span></div>
                        <Select className=" my-2 " options={technicalskillsoptions} isSearchable isMulti onChange={(value) => { seteditobject({ ...editobject, mandatory_skillset: value?.map((it) => it.value) }) }}
                          value={typeof (editobject?.mandatory_skillset) === "string" ? editobject?.mandatory_skillset?.includes(",") ? editobject?.mandatory_skillset.split(",").map((it) => { return { label: it, value: it } }) : { label: editobject?.mandatory_skillset, value: editobject?.mandatory_skillset } : editobject?.mandatory_skillset?.map((it) => { return { label: it, value: it } })}
                        />
                      </li>
                      <li className='my-3'>
                        <div>Good to Have Skillset<span className="text-danger">*</span></div>
                        <Select className=" my-2 " options={technicalskillsoptions} isSearchable isMulti onChange={(value) => { seteditobject({ ...editobject, good_to_have_skillset: value?.map((it) => it.value) }) }}
                          value={typeof (editobject?.good_to_have_skillset) === "string" ? editobject?.good_to_have_skillset?.includes(",") ? editobject?.good_to_have_skillset.split(",").map((it) => { return { label: it, value: it } }) : { label: editobject?.good_to_have_skillset, value: editobject?.good_to_have_skillset } : editobject?.good_to_have_skillset?.map((it) => { return { label: it, value: it } })}
                        />
                      </li>
                      <li className='my-3'>
                        <div>Soft Skills<span className="text-danger">*</span></div>
                        <Select className=" my-2 " options={soft_skillsoptions} isSearchable isMulti onChange={(value) => { seteditobject({ ...editobject, soft_skills: value?.map((it) => it.value) }) }}
                          value={typeof (editobject?.soft_skills) === "string" ? editobject?.soft_skills?.includes(",") ? editobject?.soft_skills.split(",").map((it) => { return { label: it, value: it } }) : { label: editobject?.soft_skills, value: editobject?.soft_skills } : editobject?.soft_skills?.map((it) => { return { label: it, value: it } })}
                        />
                      </li>
                    </ol>
                  </li>
                  <li className='my-3'>
                    <div>Summary<span className="text-danger">*</span></div>
                    <textarea rows="4" type="text" className='my-2 form-control' value={editobject.summary} onChange={(e) => seteditobject({ ...editobject, summary: e.target.value })} />
                  </li>
                  <li>
                    <div>Relevant Experience (in Years)<span className="text-danger">*</span></div>
                    <div className='row  my-2'>
                      <div className='col-sm text-start'>Min:</div>
                      <div className='col-sm text-start'>Max:</div>
                    </div>
                    <div className='d-flex justify-content-between my-auto'>
                      <input className="form-control mx-2" type="number" style={{ width: "100px" }} min={0} value={editobject.relevant_experience_min} onChange={(e) => seteditobject({ ...editobject, relevant_experience_min: e.target.value })}
                        max={15} />

                      <Slider className="mx-2 my-2" value={editobject.relevant_experience_min}
                        min={0} step={1} onChange={(e) => seteditobject({ ...editobject, relevant_experience_min: e.target.value })}
                        max={15} />

                      <input className="form-control mx-2 " type="number" style={{ width: "100px" }} min={0} value={editobject.relevant_experience_max} onChange={(e) => seteditobject({ ...editobject, relevant_experience_max: e.target.value })}
                        max={15} />
                      <Slider className="mx-2 my-2" value={editobject.relevant_experience_max}
                        min={0}
                        max={15} onChange={(e) => seteditobject({ ...editobject, relevant_experience_max: e.target.value })} />
                    </div>
                  </li>
                  <li className='my-3'>
                    <div>Notice Period (in Days)<span className="text-danger">*</span></div>
                    <select className='form-select my-1' value={editobject.notice_period_days} onChange={(e) => seteditobject({ ...editobject, notice_period_days: e.target.value })}>
                      <option>Select</option>
                      <option>Immediate</option>
                      <option>15</option>
                      <option>30</option>
                      <option>60</option>
                      <option>90</option>

                    </select>
                    {/* <input className='form-control my-2' placeholder='Days' type="number" min={0} max={90} value={editobject.notice_period_days} onChange={(e)=>seteditobject({...editobject,notice_period_days:e.target.value})}  /> */}
                  </li>
                  <li className='my-3'>
                    <div>Job Type<span className="text-danger">*</span></div>

                    <div>
                      <input type='radio' checked={editobject.job_type === "Full Time"} name="job_type" className='mx-1' value="Full Time" onChange={(e) => { seteditobject({ ...editobject, job_type: e.target.value }) }} />
                      <label>Full Time</label>

                    </div>
                    <div> <input type='radio' checked={editobject.job_type === "Contractual"} name="job_type" className='mx-1' value="Contractual" onChange={(e) => seteditobject({ ...editobject, job_type: e.target.value })} /><label>Contractual</label></div>

                    {/* <input className='form-control my-2' placeholder='Days' type="number" min={0} max={90} value={editobject.notice_period_days} onChange={(e)=>seteditobject({...editobject,notice_period_days:e.target.value})}  /> */}
                  </li>
                  <li className='my-3'>
                    <div>Reason of recruitment?<span className="text-danger">*</span></div>
                    <div>
                      <input type='radio' name="reason_for_recruitment" checked={editobject.reason_for_recruitment === "Back Filled"} className='mx-1' value="Back Filled" onChange={(e) => seteditobject({ ...editobject, reason_for_recruitment: e.target.value })} />
                      <label>Back Filled</label>

                    </div>
                    <div> <input type='radio' checked={editobject.reason_for_recruitment === "Fresh"} name="reason_for_recruitment" className='mx-1' value="Fresh" onChange={(e) => seteditobject({ ...editobject, reason_for_recruitment: e.target.value })} /><label>Fresh</label></div>

                    {/* <input className='form-control my-2' placeholder='Days' type="number" min={0} max={90} value={editobject.notice_period_days} onChange={(e)=>seteditobject({...editobject,notice_period_days:e.target.value})}  /> */}
                  </li>

                  <li className='my-3'>
                    <div>Preferred Working Location<span className="text-danger">*</span></div>
                    <select className='form-select my-2' value={typeof (editobject.preferred_working_location) === "string" && editobject.preferred_working_location.includes("Client") ? editobject.preferred_working_location?.split(" ")[0] : editobject.preferred_working_location} onChange={(e) => { e.target.value !== "Select" && seteditobject({ ...editobject, preferred_working_location: e.target.value }) }}>
                      <option>
                        Select</option>
                      <option>Bangalore</option>
                      <option>Lucknow</option>
                      <option>Client</option>
                    </select>
                  </li>
                  {typeof (editobject.preferred_working_location) === "string" && editobject.preferred_working_location?.includes("Client") && <input className='form-control' placeholder="Client City" value={editobject.preferred_working_locationoth} onChange={(e) => {
                    { seteditobject({ ...editobject, preferred_working_locationoth: e.target.value, preferred_working_location: "Client" }) }
                  }} />}

                  <li className='my-3'><div>Budget Band (in lacs)<span className="text-danger">*</span></div>
                    <span className='d-flex justify-content-between my-2'>
                      <input className='form-control mx-2' placeholder='Min Budget Range' type="number" min={0} value={editobject.budget_min_range} onChange={(e) => { seteditobject({ ...editobject, budget_min_range: e.target.value }) }} />
                      <input className='form-control mx-2' placeholder='Max Budget Range' type="number" min={0} value={editobject.budget_max_range} onChange={(e) => { seteditobject({ ...editobject, budget_max_range: e.target.value }) }} />

                    </span>

                  </li>
                  <li className='mb-3'>
                    <div>Customer<span className="text-danger">*</span></div>
                    <select className='form-select my-2' placeholder='New Job Title ' value={editobject.customer_type} onChange={(e) => { seteditobject({ ...editobject, customer_type: e.target.value, customer: "" }); }}>
                      <option>Select</option>
                      <option>Client</option>
                      <option>Internal</option>
                      <option>Bench</option>
                    </select>
                    {editobject.customer_type === "Client" && <div>
                      <select className='form-select my-2' value={editobject.customer} onChange={(e) => seteditobject({ ...editobject, customer: e.target.value })}>
                        <option>Select</option>
                        {clientlist?.map((item) => {
                          return <option>{item.client_name}</option>
                        })}
                      </select>
                    </div>}
                    {editobject.customer_type === "Bench" &&
                      <select className="form-select my-2" value={editobject.customer} onChange={(e) => seteditobject({ ...editobject, customer: e.target.value })}>
                        <option>Select</option>
                        {verticallist?.map((item) => {
                          return <option>{item}</option>
                        })}
                      </select>
                    }
                    {editobject.customer_type === "Internal" &&
                      <select className="form-select my-2" value={editobject.customer} onChange={(e) => seteditobject({ ...editobject, customer: e.target.value })}>
                        <option>Select</option>
                        {verticallist?.map((item) => {
                          return <option>{item}</option>
                        })}
                      </select>
                    }

                  </li>
                  <li className='mb-3'>
                    <div>No of Positions<span className="text-danger">*</span></div>
                    <input className='form-select my-2' placeholder='No of vacancies' value={editobject.no_of_positions} onChange={(e) => seteditobject({ ...editobject, no_of_positions: e.target.value })} />


                  </li>
                  <li className='my-3'>
                    <div>Panelist <span className="text-danger">*</span></div>
                    <Select
                      className="my-2"
                      options={employeelist}
                      isSearchable
                      isMulti
                      value={typeof editobject?.panel_list === "string"
                        ? editobject?.panel_list?.includes(",")
                          ? editobject?.panel_list.split(",").map((it) => ({ label: it, value: it }))
                          : { label: editobject?.panel_list, value: editobject?.panel_list }
                        : editobject?.panel_list?.map((it) => ({ label: it, value: it }))
                      }
                      onChange={(value) => {
                        seteditobject((prevState) => ({
                          ...prevState,
                          panel_list: value?.map((it) => it.value),
                        }));
                      }}
                    />
                  </li>

                  <li className='mb-3'>
                    <div>Approved By : </div>
                    <select className='form-select' value={editobject.vertical_head_id} onChange={(e) => { seteditobject({ ...editobject, vertical_head_id: e.target.value }) }}>
                      {
                        verticalHeadList?.map((item) => {
                          return <option value={item.vertical_head}>{item.emp_name}({item.vertical_name})</option>
                        })
                      }
                    </select>
                  </li>
                  <li className='mb-3'>
                    <div>Remarks</div>
                    <input className="form-control my-2" placeholder='Remarks' value={editobject.remarks} onChange={(e) => { seteditobject({ ...editobject, remarks: e.target.value }) }} />
                  </li>
                </ol>
                <h6>{isSubmitting ? <span className='text-success'>Job Posting Is Editting... Wait....</span> : <span className='text-danger'>Please check before submitting!!</span>}</h6>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  handleedit()
                }}
                disabled={isSubmitting ? true : false}
              >
                {editobject.current_status == 3.2 || editobject.current_status == 3.3 ? 'Make Active' : 'Submit'}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* The Visible Landed Page */}
      <div >
        {/* <img src={bgpage} className='mx-auto'/> */}
        <div className='container dashboard '>
          <div className='p-4'>
            <div className='my-2 d-flex flex-row-reverse '>
              <button className='btn btn-primary  my-2' onClick={() => setnewjobcreationscreen(!newjobcreationscreen)}>{newjobcreationscreen ? "View already created jobs" : "Create a new Job"}</button>
            </div>
            <h4 className='orangetheme'> Job Postings</h4>
            {
              newjobcreationscreen
                ?
                <div className='p-5 card'>
                  <button className='btn btn-outline-danger btn-sm m-2 ' style={{ width: "75px" }} onClick={() => setnewjobcreationvalue(initialnewjobcreationvalue)}>Reset</button>

                  <ol type="1" className='my-3'>

                    <li className='mb-3'>
                      <div>Job ID</div>
                      <input className="form-control my-2" disabled placeholder='Job ID' />
                    </li>
                    <li className='mb-3'>
                      <div>Designation<span className="text-danger">*</span></div>
                      <select className='my-2 form-select' value={newjobcreationvalue.designation} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, designation: e.target.value })}>
                        <option>Select</option>
                        {designationlist?.map((item) => {
                          return <option>{item}</option>
                        })}
                      </select>
                    </li>
                    <li className='mb-3'>
                      <div>Job Title<span className="text-danger">*</span></div>
                      <div className='row'>
                        <select className='my-2 form-select col-sm mx-2' value={newjobcreationvalue.job_position} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, job_position: e.target.value })}>
                          <option>Select</option>
                          {job_positionlist?.map((item) => {
                            return <option>{item.job_title}</option>
                          })}
                          <option>Others</option>
                        </select>
                        {newjobcreationvalue.job_position === "Others" &&
                          <input className="form-control my-2 col-sm mx-1" placeholder='New Job Title ' value={newjobcreationvalue.jobtitle} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, jobtitle: e.target.value })} />
                        }
                      </div>
                    </li>
                    <li className='my-3'>
                      Skills
                      <ol type="a">
                        <li className='my-3'>
                          <div>Mandatory Skillset<span className="text-danger">*</span></div>
                          <Select className=" my-2 " options={technicalskillsoptions} isSearchable isMulti
                            onChange={(value) => { setnewjobcreationvalue({ ...newjobcreationvalue, mandatory_skillset: value?.map((it) => it.value) }) }} value={newjobcreationvalue?.mandatory_skillset?.length > 0 && newjobcreationvalue?.mandatory_skillset?.map((it) => ({ label: it, value: it }))} />
                        </li>
                        <li className='my-3'>
                          <div>Good to Have Skillset<span className="text-danger">*</span></div>
                          <Select className=" my-2 " options={technicalskillsoptions} isSearchable isMulti onChange={(value) => { setnewjobcreationvalue({ ...newjobcreationvalue, good_to_have_skillset: value?.map((it) => it.value) }) }} value={newjobcreationvalue?.good_to_have_skillset?.length > 0 && newjobcreationvalue?.good_to_have_skillset?.map((it) => ({ label: it, value: it }))} />
                        </li>
                        <li className='my-3'>
                          <div>Soft Skills<span className="text-danger">*</span></div>
                          <Select className=" my-2 " options={soft_skillsoptions} isSearchable isMulti onChange={(value) => { setnewjobcreationvalue({ ...newjobcreationvalue, soft_skills: value?.map((it) => it.value) }) }} value={newjobcreationvalue?.soft_skills?.length > 0 && newjobcreationvalue?.soft_skills?.map((it) => ({ label: it, value: it }))} />
                        </li>
                      </ol>
                    </li>
                    <li className="my-3">
                      <div>
                        Summary <span className="text-danger">*</span>
                      </div>
                      <textarea
                        rows="4"
                        type="text"
                        className="my-2 form-control"
                        value={newjobcreationvalue.summary}
                        onChange={handleSummaryChange}
                      />
                      <small className={`text-${wordCount < 100 ? 'danger' : 'muted'}`}>
                        {wordCount} / 50 words
                      </small>
                    </li>
                    <li>
                      <div>Relevant Experience (in Years)<span className="text-danger">*</span></div>
                      <div className='row my-2'>
                        <div className='col-sm text-start'>Min:</div>
                        <div className='col-sm text-start'>Max:</div>
                      </div>
                      <div className='d-flex justify-content-between my-auto'>
                        <input
                          className="form-control mx-2"
                          type="number"
                          style={{ width: "48%" }}
                          min={0}
                          value={newjobcreationvalue.relevant_experience_min}
                          onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, relevant_experience_min: e.target.value })}
                          max={15}
                          default={0}
                        />
                        <input
                          className="form-control mx-2"
                          type="number"
                          style={{ width: "48%" }}
                          min={0}
                          value={newjobcreationvalue.relevant_experience_max}
                          onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, relevant_experience_max: e.target.value })}
                          max={15}
                          default={15}
                        />
                      </div>
                    </li>

                    <li className='my-3'>
                      <div>Notice Period (in Days)<span className="text-danger">*</span></div>
                      <select className='form-select my-1' value={newjobcreationvalue.notice_period_days} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, notice_period_days: e.target.value })}>
                        <option>Select</option>
                        <option>Immediate</option>
                        <option>15</option>
                        <option>30</option>
                        <option>60</option>
                        <option>90</option>
                      </select>
                      {/* <input className='form-control my-2' placeholder='Days' type="number" min={0} max={90} value={newjobcreationvalue.notice_period_days} onChange={(e)=>setnewjobcreationvalue({...newjobcreationvalue,notice_period_days:e.target.value})}  /> */}
                    </li>
                    <li className='my-3'>
                      <div>Job Type<span className="text-danger">*</span></div>

                      <div>
                        <input type='radio' checked={newjobcreationvalue.job_type === "Full Time"} name="job_type" className='mx-1' value="Full Time" onChange={(e) => { setnewjobcreationvalue({ ...newjobcreationvalue, job_type: e.target.value }) }} />
                        <label>Full Time</label>

                      </div>
                      <div> <input type='radio' name="job_type" className='mx-1' value="Contractual" onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, job_type: e.target.value })} /><label>Contractual</label></div>

                      {/* <input className='form-control my-2' placeholder='Days' type="number" min={0} max={90} value={newjobcreationvalue.notice_period_days} onChange={(e)=>setnewjobcreationvalue({...newjobcreationvalue,notice_period_days:e.target.value})}  /> */}
                    </li>
                    <li className='my-3'>
                      <div>Reason of recruitment?<span className="text-danger">*</span></div>
                      <div>
                        <input type='radio' name="reason_for_recruitment" className='mx-1' value="Back Filled" onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, reason_for_recruitment: e.target.value })} />
                        <label>Back Filled</label>
                      </div>
                      <div> <input type='radio' name="reason_for_recruitment" className='mx-1' checked={newjobcreationvalue.reason_for_recruitment === "Fresh"} value="Fresh" onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, reason_for_recruitment: e.target.value })} /><label>Fresh</label></div>
                      {/* <input className='form-control my-2' placeholder='Days' type="number" min={0} max={90} value={newjobcreationvalue.notice_period_days} onChange={(e)=>setnewjobcreationvalue({...newjobcreationvalue,notice_period_days:e.target.value})}  /> */}
                    </li>

                    <li className='my-3'>
                      <div>Preferred Working Location<span className="text-danger">*</span></div>
                      <select className='form-select my-2' value={newjobcreationvalue.preferred_working_location} onChange={(e) => { e.target.value !== "Select" && setnewjobcreationvalue({ ...newjobcreationvalue, preferred_working_location: e.target.value }) }}>
                        <option>Select</option>
                        <option>Bangalore</option>
                        <option>Lucknow</option>
                        <option>Client</option>
                      </select>
                    </li>
                    {newjobcreationvalue.preferred_working_location === "Client" &&
                      <input className='form-control' placeholder="Client City"
                        onChange={(e) => {
                          if (newjobcreationvalue.preferred_working_location === "Client") { setnewjobcreationvalue({ ...newjobcreationvalue, preferred_working_locationoth: e.target.value }) }
                        }} />
                    }

                    <li className="my-3">
                      <div>
                        Budget Band (per annum)<span className="text-danger">*</span>
                      </div>
                      <span className="d-flex justify-content-between my-2">
                        {/* Min Budget Input */}
                        <input
                          className={`form-control mx-2 ${invalidMin ? 'is-invalid' : ''}`}  // Apply 'is-invalid' class if input is invalid
                          placeholder="Min Budget Range - give exact value"
                          type="text"
                          value={newjobcreationvalue.budget_min_range}
                          onChange={handleMinChange}
                          style={{
                            borderColor: invalidMin ? 'red' : '', // Direct style to change border color
                          }}
                        />

                        {/* Max Budget Input */}
                        <input
                          className={`form-control mx-2 ${invalidMax ? 'is-invalid' : ''}`}  // Apply 'is-invalid' class if input is invalid
                          placeholder="Max Budget Range - give exaxt value"
                          type="text"
                          value={newjobcreationvalue.budget_max_range}
                          onChange={handleMaxChange}
                          style={{
                            borderColor: invalidMax ? 'red' : '', // Direct style to change border color
                          }}
                        />
                      </span>

                      {/* Optionally, show error messages */}
                      {invalidMin && <div className="text-danger">Please enter a valid number for Min Budget.</div>}
                      {invalidMax && <div className="text-danger">Please enter a valid number for Max Budget.</div>}

                      <div className="my-2">
                        {newjobcreationvalue.budget_min_range && !invalidMin && (
                          <p>Min Budget: {minBudgetInWords}</p>
                        )}
                        {newjobcreationvalue.budget_max_range && !invalidMax && (
                          <p>Max Budget: {maxBudgetInWords}</p>
                        )}
                      </div>
                    </li>


                    <li className='mb-3'>
                      <div>Customer<span className="text-danger">*</span></div>
                      <select className='form-select my-2' placeholder='New Job Title ' 
                        value={newjobcreationvalue.customer_type} 
                        onChange={(e) => { setnewjobcreationvalue({ ...newjobcreationvalue, customer_type: e.target.value, customer: "" }); }}>
                        <option>Select</option>
                        <option>Client</option>
                        <option>Internal</option>
                        <option>Bench</option>
                      </select>
                      {newjobcreationvalue.customer_type === "Client" && <div>
                        <select className='form-select my-2' 
                          value={newjobcreationvalue.customer} 
                          onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, customer: e.target.value })}>
                          <option>Select</option>
                          {clientlist?.map((item) => {
                            return <option>{item.client_name}</option>
                          })}
                          <option>New Client</option>
                        </select>
                      </div>}
                      {
                        newjobcreationvalue.customer === 'New Client' &&
                        <input className='form-control my-2' type='text' value={newjobcreationvalue.customer === 'New Client' ? '' : null} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, customer: e.target.value })} />
                      }
                      {newjobcreationvalue.customer_type === "Bench" &&
                        <select className="form-select my-2" value={newjobcreationvalue.customer} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, customer: e.target.value })}>
                          <option>Select</option>
                          {verticallist?.map((item) => {
                            return <option>{item}</option>
                          })}
                        </select>
                      }
                      {newjobcreationvalue.customer_type === "Internal" &&
                        <select className="form-select my-2" value={newjobcreationvalue.customer} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, customer: e.target.value })}>
                          <option>Select</option>
                          {verticallist?.map((item) => {
                            return <option>{item}</option>
                          })}
                        </select>
                      }

                    </li>
                    <li className='mb-3'>
                      <div>No of Positions<span className="text-danger">*</span></div>
                      <input type='number' className='form-control my-2' placeholder='No of vacancies' value={newjobcreationvalue.no_of_positions} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, no_of_positions: e.target.value })} />
                    </li>
                    <li className='my-3'>
                      <div>Panelist <span className="text-danger">*</span></div>
                      <Select className=" my-2 " options={employeelist} isSearchable isMulti onChange={(value) => { setnewjobcreationvalue({ ...newjobcreationvalue, panel_list: value?.map((it) => it.value) }) }} value={newjobcreationvalue?.panel_list?.length > 0 && newjobcreationvalue?.panel_list?.map((it) => ({ label: it, value: it }))} />
                    </li>
                    <li className='mb-3'>
                      <div>Approved By: <span className="text-danger">*</span></div>
                      <select className='form-select my-2' placeholder='Vertical Head' value={newjobcreationvalue.vertical_head_id} onChange={(e) => { setnewjobcreationvalue({ ...newjobcreationvalue, vertical_head_id: e.target.value }) }}>
                        <option>Select</option>
                        {
                          verticalHeadList?.map((item) => {
                            if (!(item.vertical_head === UserService.getUsername().replace('q', 'Q'))) {
                              return <option value={item.vertical_head}>{item.emp_name}({item.vertical_name})</option>
                            }
                          })
                        }
                      </select>

                    </li>
                    <li className='mb-3'>
                      <div>Remarks</div>
                      <input className="form-control my-2" placeholder='Remarks' value={newjobcreationvalue.remarks} onChange={(e) => { setnewjobcreationvalue({ ...newjobcreationvalue, remarks: e.target.value }) }} />
                    </li>
                  </ol>
                  <button className='btn btn-primary float-end' data-toggle={previewscreen ? "modal" : ""}
                    data-target="#exampleModal" onClick={() => { handlepreviewscreen() }}
                    disabled={wordCount < 50}
                  >
                    Preview</button>
                </div>
                :
                jobpostinglist?.length > 0 ?
                  <div>
                    <div className="container-fluid px-3 py-3 my-4 bg-light rounded shadow-sm">
                      <div className="row g-2 align-items-center">
                        {/* Filter By */}
                        <div className="col-12 col-md-2 d-flex align-items-center">
                          <span className="fw-bold text-nowrap me-2">Filter By:</span>
                          <i className="fa fa-filter text-primary"></i>
                        </div>

                        {/* Status Filter */}
                        <div className="col-6 col-md-2">
                          <label htmlFor="statusFilter" className="form-label small mb-1 fw-semibold">Status:</label>
                          <select
                            id="statusFilter"
                            className="form-select form-select-sm"
                            value={statusfilter}
                            onChange={(e) => handlefiltervariables("status", e.target.value)}
                          >
                            <option>Select</option>
                            <option value="open">Open</option>
                            <option value="closed">Closed</option>
                          </select>
                        </div>

                        {/* Created By Filter */}
                        <div className="col-6 col-md-3">
                          <label htmlFor="createdByFilter" className="form-label small mb-1 fw-semibold">Created By:</label>
                          <select
                            id="createdByFilter"
                            className="form-select form-select-sm"
                            value={createdbyfilter}
                            onChange={(e) => handlefiltervariables("created_by", e.target.value)}
                          >
                            <option>Select</option>
                            {Array.from(new Set(jobpostinglist?.map((item) => item.created_by))).map((it) => (
                              <option key={it}>{it}</option>
                            ))}
                          </select>
                        </div>

                        {/* Date Range Filter */}
                        <div className="col-12 col-md-4">
                          <label htmlFor="dateRange" className="form-label small mb-1 fw-semibold">Date Range:</label>
                          <div className="d-flex flex-wrap gap-2">
                            <input
                              id="startDate"
                              className="form-control form-control-sm"
                              type="date"
                              value={startdatestate}
                              onChange={(e) => handlefiltervariables("startdate", e.target.value)}
                            />
                            <input
                              id="endDate"
                              className="form-control form-control-sm"
                              type="date"
                              value={enddatestate}
                              onChange={(e) => handlefiltervariables("enddate", e.target.value)}
                            />
                          </div>
                        </div>
                        {/* Apply Filters Button */}
                        <div className="col-12 col-md-1 text-md-end text-center mt-2 mt-md-0">
                          <button
                            className="btn btn-sm btn-primary w-100"
                            onClick={() => alert('Functionality is under process')}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* The list of all the  */}
                    <div>
                      {
                        jobpostingfilteredlist?.length > 0 ?
                          jobpostingfilteredlist?.map((item) => {
                            return <>
                              <div
                                className="row border rounded shadow mx-2 my-3 p-2"
                                style={{ minHeight: "100px" }}
                              >
                                {/* Left Section */}
                                <div
                                  className="col-12 col-md-4 bg-light rounded p-3 position-relative text-center"
                                >
                                  {/* Status Label */}
                                  <div
                                    className={`position-absolute text-white p-1 rounded text-xs
                                      ${item.current_status === 1
                                        ? 'bg-info'
                                        : (item.current_status === 2
                                          ? 'bg-primary'
                                          : (
                                            item.current_status === 3.1 ? 'bg-success'
                                              : item.current_status === 3.2 ? 'bg-warning text-dark'
                                                : item.current_status === 3.3 ? 'bg-danger' : 'bg-secondary'
                                          ))
                                      }`
                                    }
                                    style={{ top: "5px", left: "5px", fontSize: "12px" }}
                                  >
                                    {capitalizefirstleter(item.status_name)}
                                  </div>
                                  {/* Job Position */}
                                  <div className="fw-bold mt-4">{item.job_position}</div>
                                </div>

                                {/* Middle Section */}
                                <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                                  <div className="row">
                                    <div className="col-6">
                                      <p className="mb-1">
                                        <strong>Date Created:</strong> {formatdate(item.date_created)}
                                      </p>
                                      <p className="mb-1">
                                        <strong>Date Closed:</strong>{" "}
                                        {item.date_closed ? formatdate(item.date_closed) : "N/A"}
                                      </p>
                                    </div>
                                    <div className="col-6">
                                      <p className="mb-1">
                                        <strong>Created By:</strong>
                                        {
                                          getNameofcreatedby(item.created_by)
                                        }

                                      </p>
                                      <p className="mb-1">
                                        <strong>Status:</strong>{" "}
                                        {item.current_status >= 4.0
                                          ? item.action_remark
                                          : `${item.status_name}${item.current_status > 2 ? ` By: ${item.action_taken_by.replace('c', 'C')}` : ''}`
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                {/* Right Section */}
                                <div className="col-12 col-md-2 d-flex justify-content-end align-items-center">
                                  {/* Action Buttons */}
                                  <div className="row justify-content-center align-items-center g-3">
                                    {/* Edit Job Posting Button */}
                                    {
                                      !((item.created_by === UserService.getUsername().replace("q", "Q")) ||
                                        (item.vertical_head_id != UserService.getUsername().replace("q", "Q") && item.current_status == 2) ||
                                        (item.current_status == 1 && item.vertical_head_id === UserService.getUsername().replace("q", "Q")) ||
                                        (item.current_status == 2 && checkRecruiterHead(item.assigned_to)) ||
                                        (item.current_status == 3.1) || (item.current_status === 4.0) ||
                                        ((item.current_status == 3.2 || item.current_status == 3.3) && !checkRecruiterHead(UserService.getUsername().replace('q', 'Q'))))
                                      &&
                                      <div className="col-auto">
                                        <button
                                          className={`btn btn-sm ${(item.created_by === UserService.getUsername().replace("q", "Q")) ||
                                            (item.current_status == 1 && item.vertical_head_id === UserService.getUsername().replace("q", "Q")) ||
                                            (item.vertical_head_id != UserService.getUsername().replace("q", "Q") && item.current_status == 2) ||
                                            (item.current_status == 2 && checkRecruiterHead(item.assigned_to)) || (item.current_status == 3.1) ||
                                            ((item.current_status == 3.2 || item.current_status == 3.3) && !checkRecruiterHead(UserService.getUsername().replace('q', 'Q')))
                                            ? "btn-secondary"
                                            : "btn-primary"
                                            }`}
                                          onClick={() => {
                                            seteditscreen(true);
                                            seteditobject({
                                              ...item,
                                              jobtitle: item.job_position?.split(" ")?.slice(1).join(" "),
                                              preferred_working_locationoth: item.preferred_working_location?.split(" ")?.slice(1).join(" "),
                                            });
                                          }}
                                          data-toggle={item.created_by === UserService.getUsername() &&
                                            UserService.getUsername() !== REACT_APP_ADMIN_USER ? "" : "modal"}
                                          data-target="#exampleModal3"
                                          disabled={
                                            (item.created_by === UserService.getUsername().replace("q", "Q")) ||
                                              (item.vertical_head_id != UserService.getUsername().replace("q", "Q") && item.current_status == 2) ||
                                              (item.current_status == 1 && item.vertical_head_id === UserService.getUsername().replace("q", "Q")) ||
                                              (item.current_status == 2 && checkRecruiterHead(item.assigned_to)) ||
                                              (item.current_status == 3.1) ||
                                              ((item.current_status == 3.2 || item.current_status == 3.3) && !checkRecruiterHead(UserService.getUsername().replace('q', 'Q')))
                                              ? true : false
                                          }
                                          title="Edit Job Posting"
                                        >
                                          <i className="fa fa-edit"></i> {item.current_status === 3.2 || item.current_status === 3.3 ? 'Make Active' : 'Edit'}
                                        </button>
                                      </div>
                                    }

                                    {/* View Job Posting Button */}
                                    <div className="col-auto">
                                      <button
                                        className="btn btn-success btn-sm"
                                        onClick={() => setviewobject(item)}
                                        data-toggle="modal"
                                        data-target="#exampleModal2"
                                        title="View Job Posting"
                                      >
                                        <i className="fa fa-eye"></i> View
                                      </button>
                                    </div>

                                    {/* Proceed Job Posting Forward Button */}
                                    {
                                      item.current_status === 1 &&
                                      recruitmentHeadList.map((head) => {
                                        if (head.recruitment_head_emp_id === UserService.getUsername().replace("q", "Q")) {
                                          return (
                                            <div className="col-auto" key={head.recruitment_head_emp_id}>
                                              {
                                                isSubmitting ? (
                                                  <div className="d-flex justify-content-center align-items-center">
                                                    <Spinner animation="border" variant="primary" />
                                                    <span className="ms-2">Submitting...</span> {/* Optional text */}
                                                  </div>
                                                ) : (
                                                  <button
                                                    className="btn btn-warning btn-sm d-flex align-items-center"
                                                    onClick={() => {
                                                      changeJobStatus(item.job_id, item.current_status);
                                                      setIsSubmitting(true);
                                                    }}
                                                    title="Proceed"
                                                    disabled={isSubmitting}
                                                  >
                                                    <i className="fa fa-forward mr-2"></i> Proceed
                                                  </button>
                                                )
                                              }
                                            </div>
                                          );
                                        }
                                      })
                                    }
                                    {/* Assign Job Posting Button */}
                                    {item.current_status == 3.1 && checkRecruiterHead(UserService.getUsername().replace('q', 'Q')) &&
                                      <div className="col-auto">
                                        <button
                                          className="btn btn-primary btn-sm"
                                          onClick={() => {
                                            setSpaecifiJobDetail(item)
                                            setShowAssignModal(true)
                                            setActionJobID(item.job_id)
                                          }
                                          }
                                          data-bs-target="#assignModal"
                                          title="Assign Job Posting"
                                        >
                                          <i className="fa fa-user-plus"></i> Assign
                                        </button>
                                      </div>
                                    }

                                    {item.current_status == 4.0 && checkRecruiterHead(UserService.getUsername().replace('q', 'Q')) &&
                                      <div className="col-auto">
                                        <button
                                          className="btn btn-primary btn-sm"
                                          onClick={() => {
                                            setSpaecifiJobDetail(item)
                                            setShowAssignModal(true)
                                            setActionJobID(item.job_id)
                                          }
                                          }
                                          data-bs-target="#assignModal"
                                          title="Assign Job Posting"
                                        >
                                          <i className="fa fa-user-plus"></i> Re-Assign
                                        </button>
                                      </div>
                                    }

                                    {/* View Remark Button */}
                                    {(item.current_status == 3.2 || item.current_status == 3.3) && checkRecruiterHead(UserService.getUsername().replace('q', 'Q')) &&
                                      <div className="col-auto">
                                        <button
                                          className="btn btn-info btn-sm"
                                          onClick={() => {
                                            dispatch(
                                              getNotification({
                                                message: `${item.action_remark}`,
                                                type: `${item.current_status == 3.2 ? 'warning' : 'danger'}`,
                                              })
                                            )
                                          }}
                                          data-toggle="modal"
                                          data-target="#remarkModal"
                                          title="View Remark"
                                        >
                                          <i className="fa fa-comments"></i> View Remark
                                        </button>
                                      </div>
                                    }
                                    {/* Approval Buttons */}
                                    {
                                      isSubmitting & item.current_status === 2 ? (
                                        <div className="d-flex justify-content-center align-items-center">
                                          <Spinner animation="border" variant="primary" />
                                          <span className="ms-2">Submitting...</span> {/* Optional text */}
                                        </div>
                                      ) : (
                                        (
                                          (item.current_status === 2 &&
                                            (item.vertical_head_id === UserService.getUsername().replace('q', 'Q') ||
                                              item.assigned_to === UserService.getUsername().replace('qc', 'QC'))
                                          ) ||
                                          (
                                            item.vertical_head_id === UserService.getUsername().replace('q', 'Q') &&
                                            !checkRecruiterHead(item.assigned_to) &&
                                            item.current_status === 2
                                          )
                                        ) ? (
                                          <div className="col-auto btn-group gap-2" role="group" aria-label="Approval actions">
                                            <button
                                              className="btn btn-success btn-sm"
                                              onClick={() => changeJobStatus(item.job_id, 3.1, 'Approved')}
                                              title="Approve"
                                              disabled={showHoldRemarkInput || showRejectRemarkInput}
                                            >
                                              <i className="fa fa-check"></i> Approve
                                            </button>
                                            <button
                                              className="btn btn-warning btn-sm"
                                              onClick={() => {
                                                setShowHoldModal(true); // Open the modal
                                                setShowHoldRemarkInput(true);
                                                setActionJobID(item.job_id);
                                              }}
                                              title="Hold"
                                              disabled={showHoldRemarkInput || showRejectRemarkInput}
                                            >
                                              <i className="fa fa-pause"></i> Hold
                                            </button>

                                            <button
                                              className="btn btn-danger btn-sm"
                                              onClick={() => {
                                                setShowRejectModal(true);
                                                setShowRejectRemarkInput(true);
                                                setActionJobID(item.job_id);
                                              }}
                                              title="Reject"
                                              disabled={showHoldRemarkInput || showRejectRemarkInput}
                                            >
                                              <i className="fa fa-times"></i> Reject
                                            </button>
                                          </div>
                                        ) : null
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </>
                          }) : <div>There are no matching records</div>
                      }
                    </div>
                  </div>
                  : <div>There are no existing job postings</div>
            }
            {/* <!--Hold Modal --> */}
            <div
              className={`modal fade ${showHoldModal ? 'show' : ''}`}
              id="holdModal"
              tabIndex="-1"
              aria-labelledby="rejectModalLabel"
              aria-hidden={!showHoldModal} // Close the modal when hidden
              style={{ display: showHoldModal ? 'block' : 'none' }} // To manage the display property
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="rejectModalLabel">Add Hold Remark</h5>
                    <button
                      id="closeHoldModal"
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setShowHoldModal(false); // Close the modal manually
                        setShowHoldRemarkInput(false);
                        setActionRemarks('');
                      }}
                      disabled={isSubmitting ? true : false}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <textarea
                      className="form-control"
                      rows="4"
                      placeholder="Enter your remarks..."
                      id="remark"
                      value={actionRemarks}
                      onChange={(e) => setActionRemarks(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setShowHoldModal(false); // Close the modal manually
                        setShowHoldRemarkInput(false);
                        setActionRemarks('');
                      }}
                      disabled={isSubmitting ? true : false}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        changeJobStatus(actionJobID, 3.2, actionRemarks);
                      }}
                      disabled={isSubmitting ? true : false}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>


            {/* <!--Reject Modal --> */}
            <div
              className={`modal fade ${showRejectModal ? 'show' : ''}`}
              id="RejectModal"
              tabIndex="-1"
              aria-labelledby="rejectModalLabel"
              aria-hidden={!showRejectModal}
              style={{ display: showRejectModal ? 'block' : 'none' }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="rejectModalLabel">Add Reject Remark</h5>
                    <button
                      id='closeRejectModal'
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setShowRejectModal(false)
                        setShowRejectRemarkInput(false)
                        setActionRemarks('')
                      }
                      }
                      disabled={isSubmitting ? true : false}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <textarea
                      className="form-control"
                      rows="4"
                      placeholder="Enter your remarks..."
                      id="remark"
                      value={actionRemarks}
                      onChange={(e) => setActionRemarks(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setShowRejectModal(false)
                        setShowRejectRemarkInput(false)
                        setActionRemarks('')
                      }
                      }
                      disabled={isSubmitting ? true : false}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        changeJobStatus(actionJobID, 3.3, actionRemarks)
                      }}
                      disabled={isSubmitting ? true : false}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Select Assignee Modal */}
            <div
              className={`modal ${showAssignModal ? 'show' : ''}`} // Removed 'fade' class
              id="assignModal"
              tabIndex="-1"
              aria-labelledby="recruitmentAssigneeModalLabel"
              aria-hidden={!showAssignModal}
              style={{ display: showAssignModal ? 'block' : 'none' }} // React handles the display
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="recruitmentAssigneeModalLabel">
                      Select Recruitment Assignee
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      id="assignCloseBtn"
                      onClick={() => {
                        setShowAssignModal(false);  // Close modal by setting the state
                        setSelectedAssignee('');     // Clear selected assignee
                      }}
                      disabled={isSubmitting}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="mt-1">
                      <h5 className="text-primary mb-3"><strong>Job Details</strong></h5>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          <strong>JobId:</strong> <span className="text-muted">{specificJobDetail?.job_id}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          <strong>Job Position:</strong> <span className="text-muted">{specificJobDetail?.job_position}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          <strong>Current Assigned To:</strong> <span className="text-info">{specificJobDetail?.assigned_to || "Not Assigned"}</span>
                        </li>
                      </ul>
                    </div>
                    <hr className="mx-auto border-primary border-2" />
                    <div className="mb-3 mt-2">
                      <label htmlFor="assigneeSelect" className="form-label">
                        <strong>Recruitment Assignee:</strong>
                      </label>
                      <select
                        id="assigneeSelect"
                        className="form-select"
                        value={selectedAssignee}
                        onChange={(e) => setSelectedAssignee(e.target.value)}
                        disabled={isSubmitting}
                      >
                        <option value="">Select Assignee</option>
                        {/* Populate options dynamically */}
                        {recruitmentAssigneeList.map((assignee) => (
                          <option key={assignee.assignee_emp_id}>
                            {assignee.assignee_name} - {assignee.assignee_emp_id}
                          </option>
                        ))}
                      </select>
                    </div>
                    {isSubmitting && <h5 className="text-success">Updating Please Wait......</h5>}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        setShowAssignModal(false); // Close modal by setting state
                        setSelectedAssignee('');    // Clear selected assignee
                      }}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        console.log('actionJobID', actionJobID, 'selectedAssignee', selectedAssignee);
                        changeJobStatus(actionJobID, 4.0, `Assigned To ${selectedAssignee}`);
                      }}
                      disabled={isSubmitting || selectedAssignee === ''}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <FooterPages />
    </>
  )
}

export default Dashboard