import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import ConsentDiv from "../ConsentForm";
import { Navigate, useNavigate } from "react-router-dom";

const JobApplicationForm = ({ data, jobminexpRequired, jobId }) => {
    const navigate = useNavigate()
    const FullName = `${data?.firstName || ''} ${data?.middleName ? data.middleName + ' ' : ''}${data?.lastName || ''}`.trim();
    const [form, setForm] = useState({
        currentCtc: "",
        expectedCtc: "",
        readyToRelocate: "",
        noticePeriod: "",
        reasonForChange: "",
        totalExperience: "",
        relevantExperience: "",
        currentCompany: "",
        currentDesignation: "",
        highestQualification: "",
        courseName: "",
        college: "",
        passoutYear: '',
        hearAboutUs: "",
        resume: null,
        diplomaSpecialization: ''
    });
    const [errors, setErrors] = useState({});
    const [isFresher, setIsFresher] = useState(true);
    const [resumeFileName, setResumeFileName] = useState('')
    const [resumeFileURL, setResumeFileURL] = useState('')
    const [isConsentGiven, setIsConsentGiven] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [file, setFile] = useState(null); // To store the selected file
    const [isUploading, setIsUploading] = useState(false); // To track if uploading
    const [uploadStatus, setUploadStatus] = useState(null); // To store upload status
    const [isUploaded, setIsUploaded] = useState(false); // To track if file is uploaded
    const [error, setError] = useState("");
    const fileInputRef = useRef(null);

    // File change handler
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        // Check if a file was selected
        if (selectedFile) {
            // Restrict file size to 5MB (5 * 1024 * 1024)
            const maxSize = 5 * 1024 * 1024; // 5MB
            if (selectedFile && selectedFile.type === 'application/pdf') {
                if (selectedFile.size > maxSize) {
                    setError("File size exceeds 5MB. Please upload a smaller file.");
                    setFile(null); // Reset file selection
                } else {
                    setFile(selectedFile);
                    setError(""); // Clear error if file is valid
                }
            } else {
                alert('Please select a valid PDF file');
            }
        }
    }

    // File upload handler
    const handleFileUpload = async () => {
        if (!file) return;
        setIsUploading(true);
        setUploadStatus('Uploading...');
        const formData = new FormData()
        formData.append('file', file)
        try {
            const config = {
                method: "post",
                url: `${process.env.REACT_APP_UPLOAD_DATA}/candidate/resume`,  // Make sure REACT_APP_UPLOAD_DATA is defined in .env
                headers: {
                    Accept: "application/json",  // Expect JSON response
                    'Content-Type': 'multipart/form-data', // Send data as JSON
                },
                data: formData // Ensure this is a valid object
            };
            // Send the request and await the response
            const response = await axios(config);
            if (response.status === 200) {
                setResumeFileName(response.data.file_name)
                setResumeFileURL(response.data.file_url)
                setIsUploading(false);
                setUploadStatus('Uploaded Successful');
                setIsUploaded(true)
            }
        } catch (error) {
            if (error.response) {
                // Check if the error has response data
                if (error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                    setIsUploading(false);
                    setUploadStatus('Some Error Occured');
                } else {
                    toast.error("Something went wrong! Please try again later.");
                    setIsUploading(false);
                    setUploadStatus('Some Error Occured');
                }
            } else {
                // If no response, it's a network or unexpected error
                toast.error("Network error! Please check your internet connection.");
                setIsUploading(false);
                setUploadStatus('Some Error Occured');
            }
        }
    };

    // Delete file handler
    const handleDeleteFile = async (e) => {
        e.preventDefault();
        try {
            const config = {
                method: "delete",
                url: `${process.env.REACT_APP_UPLOAD_DATA}/candidate/resume/${resumeFileName}`,
            };
            // Send the request and await the response
            const response = await axios(config);
            if (response.status === 200) {
                toast.success(response.data.message)
                setResumeFileName('')
                setResumeFileURL('')
                setFile(null);
                setIsUploaded(false);
                setUploadStatus(null);
                // Reset the file input to allow for a new file to be selected
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';  // Reset the input value
                }
            }
        } catch (error) {
            console.log(error);
            if (error.response) {
                // Handle errors
                if (error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error("Something went wrong! Please try again later.");
                }
            } else {
                toast.error("Network error! Please check your internet connection.");
            }
        }
    };


    const handleInputChange = (e) => {
        const { name, value, type, files } = e.target;
        setForm((prev) => ({
            ...prev,
            [name]: type === "file" ? files[0] : value,
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!form.currentCtc) newErrors.currentCtc = "Current CTC is required.";
        if (!form.expectedCtc) newErrors.expectedCtc = "Expected CTC is required.";
        if (!form.readyToRelocate) newErrors.readyToRelocate = "Please select an option.";
        if (!form.noticePeriod) newErrors.noticePeriod = "Notice period is required.";
        if (!form.reasonForChange) newErrors.reasonForChange = "Reason for change is required.";
        if (!form.highestQualification) newErrors.highestQualification = "Highest qualification is required.";
        if (!form.college) newErrors.college = "College/University is required.";
        if (!form.hearAboutUs) newErrors.hearAboutUs = "Please select how you heard about us.";
        if (form.hearAboutUs === 'Others') {
            if (form.customPortalName === '') {
                newErrors.customPortalName = 'Please Type the name of the portal you hear !!'
            }
        }
        if (form.hearAboutUs === 'Employee Referral') {
            if (form.employeeReferalID === '' || form.employeeReferalID === undefined) {
                newErrors.employeeReferalID = "Please enter Employee Id"
            }
            if (form.employeeReferalID != undefined) {
                if (form.employeeReferalID.length != 5) {
                    newErrors.employeeReferalID = 'Incorrect Employee Id'
                }
                if (!(form.employeeReferalID[0] === 'Q')) {
                    newErrors.employeeReferalID = 'Irrelevant Employee Id'
                }
            }

        }
        if (!isFresher && (!form.currentCompany || !form.currentDesignation || !form.relevantExperience)) {
            newErrors.currentCompany = "Current company is required.";
            newErrors.currentDesignation = "Current designation is required.";
            newErrors.relevantExperience = 'Relevant Experience is required';
        }
        if (!form.passoutYear) newErrors.passoutYear = 'Passout Year is Required'
        if (!form.courseName) newErrors.courseName = 'Course Name is required'
        if (jobminexpRequired.toString() > form.relevantExperience) {
            toast.error(`You are not eligible for this post. Company required Minimum Experience of ${jobminexpRequired} year`)
        }


        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setIsSubmitting(true)
            if (!isConsentGiven) {
                toast.warning("You must agree to the terms and conditions before submitting.");
                setIsSubmitting(false)
                return;
            }
            console.log(form)
            const formData = new FormData()
            //Making data same like backend Pydantic Data
            formData.append('job_id', jobId)
            formData.append('candidate_name', FullName)
            formData.append('candidate_email', data.email)
            formData.append('candidate_mobile', data.mobile)
            formData.append('candidate_aadhar', data.aadhar_num)
            formData.append('candidate_current_ctc', form.currentCtc)
            formData.append('candidate_expected_ctc', form.expectedCtc)
            formData.append('ready_to_reallocate', form.readyToRelocate)
            formData.append('notice_period', form.noticePeriod)
            formData.append('reason_for_change', form.reasonForChange)
            formData.append(
                'total_experience',
                form.totalExperience === 'Fresher' ? '0' : form.relevantExperience.toString()
            );
            formData.append('current_company', form.currentCompany)
            formData.append('current_designation', form.currentDesignation)
            formData.append('highest_qualification', form.highestQualification)
            formData.append('course_name', 
                form.courseName === 'Others' ? form.customCourseName : form.courseName
            )
            formData.append('passout_year', form.passoutYear)
            formData.append('college_university', form.college)
            formData.append('via', 
                form.hearAboutUs ? 
                    (form.hearAboutUs === 'Employee Referral' ? form.employeeReferalID : 
                        (form.hearAboutUs === 'Other' ? form.customPortalName : form.hearAboutUs)) 
                    : null 
            );
            
            formData.append('pdf_file_name', resumeFileName)
            formData.append('pdf_file_url', resumeFileURL)

            try {
                const config = {
                    method: "post",
                    url: `${process.env.REACT_APP_UPLOAD_DATA}/candidate/formSubmission`,  // Make sure REACT_APP_UPLOAD_DATA is defined in .env
                    headers: {
                        Accept: "application/json",  // Expect JSON response
                        "Content-Type": "application/json", // Send data as JSON
                    },
                    data: formData // Ensure this is a valid object
                };
                // Send the request and await the response
                const response = await axios(config);
                if (response.status === 200) {
                    toast.success('Application Submitted Successfully')
                    localStorage.removeItem('access_token')
                    localStorage.removeItem('jobId')
                    navigate('/candidate/login')
                    toast.success('Login Again to See the status')
                    setIsSubmitting(false)
                }

            } catch (error) {
                // Catching error if the request fails (like network issues or server errors)
                if (error.response) {
                    // Check if the error has response data
                    if (error.response.data && error.response.data.message) {
                        if (error.response.status === 409) {
                            localStorage.removeItem('access_token')
                            localStorage.removeItem('jobId')
                            navigate('/')
                            toast.error(error.response.data.message);
                        }
                        else {
                            toast.error(error.response.data.message);
                        }

                    } else {
                        toast.error("Something went wrong! Please try again later.");
                        setIsSubmitting(false)
                    }
                } else {
                    // If no response, it's a network or unexpected error
                    toast.error("Network error! Please check your internet connection.");
                    setIsSubmitting(false)
                }
            }
        }
    }

    const morphName = (name) => {
        const get_name_array = name.split(' ')
        let morphedName = ''
        for (let value of get_name_array) {
            let morphValue = value[0] + '*'.repeat(value.length - 2) + value[value.length - 1]
            morphedName = morphedName + ' ' + morphValue
        }
        return morphedName
    }


    const labelStyle = { color: "orange", fontWeight: "bold" };
    const errorStyle = { color: "red", fontSize: "small" };

    return (
        <div className="container mt-5">
            <h2 className="text-center" style={{ color: "orange" }}>
                Job Application Form
            </h2>
            <form onSubmit={handleSubmit} className="p-4 border rounded shadow">
                {/* Uneditable Fields */}
                <div className="mb-3">
                    <label style={labelStyle}>Candidate Name:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={FullName ? morphName(FullName) : "*****"}
                        readOnly
                        style={{ userSelect: 'none' }}
                    />
                </div>
                <div className="mb-3">
                    <label style={labelStyle}>Email ID:</label>
                    <input
                        type="email"
                        className="form-control"
                        value={data?.email ? data.email.split('@')[0].substring(0, 3) + "*".repeat(data.email.split('@')[0].length - 3) + "@" + data.email.split('@')[1] : "*****@*****.com"}
                        readOnly
                        style={{ userSelect: 'none' }}
                    />
                </div>
                <div className="mb-3">
                    <label style={labelStyle}>Contact No:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={data?.mobile ? "*".repeat(data.mobile.length - 2) + data.mobile.substring(data.mobile.length - 2) : "****"}
                        readOnly
                        style={{ userSelect: 'none' }}
                    />
                </div>
                <div className="mb-3">
                    <label style={labelStyle}>Aadhar No:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={data?.aadhar_num ? "*".repeat(data.aadhar_num.length - 4) + data.aadhar_num.substring(data.aadhar_num.length - 4) : "**** **** ****"}
                        readOnly
                        style={{ userSelect: 'none' }}
                    />
                </div>

                <div className="alert alert-danger" role="alert">
                    All the fields are mandatory to fill
                </div>
                {/* Editable Fields */}
                <div className="d-flex flex-wrap align-items-center gap-3 mb-3 mt-3">
                    {/* Label for file input */}
                    <label style={labelStyle} htmlFor="file-input">
                        Upload Resume (pdf only): <span className="text-danger" style={{ verticalAlign: 'middle', fontSize: '1.5em', color: '#e74c3c', fontWeight: 'bold', marginLeft: '5px' }}>*</span>
                    </label>
                    {error && <p style={{ color: "red" }}>{error}</p>}
                    {/* File input (hidden) */}
                    <input
                        type="file"
                        name="resume"
                        className="form-control"
                        accept=".pdf"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        id="file-input"
                        ref={fileInputRef}
                    />

                    {/* Label to trigger file input */}
                    {!isUploaded && (
                        <label htmlFor="file-input" className="btn btn-primary">
                            Select File
                        </label>
                    )}

                    {/* Uneditable input field to show file name */}
                    {file && !isUploaded && (
                        <input
                            type="text"
                            value={file.name}
                            className="form-control"
                            readOnly
                            style={{ width: '250px' }}
                        />
                    )}

                    {/* Upload button or spinner */}
                    {file && !isUploaded && (
                        <button
                            className="btn btn-success"
                            onClick={handleFileUpload}
                            disabled={isUploading || !file}
                        >
                            {isUploading ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                                'Upload'
                            )}
                        </button>
                    )}

                    {/* Delete button after upload */}
                    {isUploaded && (
                        <>
                            <input
                                type="text"
                                value={file.name}
                                className="form-control"
                                readOnly
                                style={{ width: '250px' }}
                            />
                            <button className="btn btn-danger" onClick={handleDeleteFile}>
                                Delete
                            </button>
                        </>
                    )}

                    {/* Upload status */}
                    {uploadStatus && <div className="text-info mt-2">{uploadStatus}</div>}
                </div>

                <div className="mb-3">
                    <label style={labelStyle}>Current CTC (in Lakhs) :</label><span className="text-danger" style={{ verticalAlign: 'middle', fontSize: '1.5em', color: '#e74c3c', fontWeight: 'bold', marginLeft: '5px' }}>*</span>

                    <input
                        type="number"
                        name="currentCtc"
                        className="form-control"
                        value={form.currentCtc}
                        onChange={handleInputChange}
                    />
                    {errors.currentCtc && <div style={errorStyle}>{errors.currentCtc}</div>}
                </div>

                <div className="mb-3">
                    <label style={labelStyle}>Expected CTC (in Lakhs) :</label><span className="text-danger" style={{ verticalAlign: 'middle', fontSize: '1.5em', color: '#e74c3c', fontWeight: 'bold', marginLeft: '5px' }}>*</span>
                    <input
                        type="number"
                        name="expectedCtc"
                        className="form-control"
                        value={form.expectedCtc}
                        onChange={handleInputChange}
                    />
                    {errors.expectedCtc && <div style={errorStyle}>{errors.expectedCtc}</div>}
                </div>
                <div className="mb-3">
                    <label style={labelStyle}>Ready to Relocate:</label><span className="text-danger" style={{ verticalAlign: 'middle', fontSize: '1.5em', color: '#e74c3c', fontWeight: 'bold', marginLeft: '5px' }}>*</span>
                    <div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="readyToRelocate"
                                value="yes"
                                checked={form.readyToRelocate === "yes"}
                                onChange={handleInputChange}
                            />
                            <label className="form-check-label">Yes</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="readyToRelocate"
                                value="no"
                                checked={form.readyToRelocate === "no"}
                                onChange={handleInputChange}
                            />
                            <label className="form-check-label">No</label>
                        </div>
                    </div>
                    {errors.readyToRelocate && <div style={errorStyle}>{errors.readyToRelocate}</div>}
                </div>

                <div className="mb-3">
                    <label style={labelStyle}>Notice Period:</label><span className="text-danger" style={{ verticalAlign: 'middle', fontSize: '1.5em', color: '#e74c3c', fontWeight: 'bold', marginLeft: '5px' }}>*</span>
                    <select
                        name="noticePeriod"
                        className="form-select"
                        value={form.noticePeriod}
                        onChange={handleInputChange}
                    >
                        <option value="">Select</option>
                        <option value="Immediate">Immediate</option>
                        <option value="15days">15 days</option>
                        <option value="30days">30 days</option>
                        <option value="45days">45 days</option>
                        <option value="60days">60 days</option>
                    </select>
                    {errors.noticePeriod && <div style={errorStyle}>{errors.noticePeriod}</div>}
                </div>

                <div className="mb-3">
                    <label style={labelStyle}>Reason for Change:</label><span className="text-danger" style={{ verticalAlign: 'middle', fontSize: '1.5em', color: '#e74c3c', fontWeight: 'bold', marginLeft: '5px' }}>*</span>
                    <input
                        type="text"
                        name="reasonForChange"
                        className="form-control"
                        value={form.reasonForChange}
                        onChange={handleInputChange}
                    />
                    {errors.reasonForChange && <div style={errorStyle}>{errors.reasonForChange}</div>}
                </div>

                <div className="mb-3">
                    <label style={labelStyle}>Total Experience:</label><span className="text-danger" style={{ verticalAlign: 'middle', fontSize: '1.5em', color: '#e74c3c', fontWeight: 'bold', marginLeft: '5px' }}>*</span>
                    <select
                        name="totalExperience"
                        className="form-select"
                        value={form.totalExperience}
                        onChange={(e) => {
                            handleInputChange(e);
                            setIsFresher(e.target.value === "fresher");
                        }}
                    >
                        <option value="">Select</option>
                        <option value="fresher">Fresher</option>
                        <option value="experienced">Experienced</option>
                    </select>
                    {errors.totalExperience && <div style={errorStyle}>{errors.totalExperience}</div>}
                </div>

                {form.totalExperience && form.totalExperience !== "" && (
                    <>
                        {(!isFresher) && (
                            <>
                                <div className="mb-3">
                                    <label style={labelStyle}>Relevant Experience:</label><span className="text-danger" style={{ verticalAlign: 'middle', fontSize: '1.5em', color: '#e74c3c', fontWeight: 'bold', marginLeft: '5px' }}>*</span>
                                    <input
                                        type="number"
                                        name="relevantExperience"
                                        className="form-control"
                                        value={form.relevantExperience}
                                        onChange={handleInputChange}
                                    />
                                    {errors.relevantExperience && <div style={errorStyle}>{errors.relevantExperience}</div>}
                                </div>
                                <div className="mb-3">
                                    <label style={labelStyle}>Current Company:</label><span className="text-danger" style={{ verticalAlign: 'middle', fontSize: '1.5em', color: '#e74c3c', fontWeight: 'bold', marginLeft: '5px' }}>*</span>
                                    <input
                                        type="text"
                                        name="currentCompany"
                                        className="form-control"
                                        value={form.currentCompany}
                                        onChange={handleInputChange}
                                    />
                                    {errors.currentCompany && <div style={errorStyle}>{errors.currentCompany}</div>}
                                </div>
                                <div className="mb-3">
                                    <label style={labelStyle}>Current Designation:</label><span className="text-danger" style={{ verticalAlign: 'middle', fontSize: '1.5em', color: '#e74c3c', fontWeight: 'bold', marginLeft: '5px' }}>*</span>
                                    <input
                                        type="text"
                                        name="currentDesignation"
                                        className="form-control"
                                        value={form.currentDesignation}
                                        onChange={handleInputChange}
                                    />
                                    {errors.currentDesignation && <div style={errorStyle}>{errors.currentDesignation}</div>}
                                </div>
                            </>
                        )}
                    </>
                )}

                <div className="mb-3">
                    <label style={labelStyle}>Highest Qualification:</label><span className="text-danger" style={{ verticalAlign: 'middle', fontSize: '1.5em', color: '#e74c3c', fontWeight: 'bold', marginLeft: '5px' }}>*</span>
                    <select
                        name="highestQualification"
                        className="form-select"
                        value={form.highestQualification}
                        onChange={handleInputChange}
                    >
                        <option value="">Select</option>
                        <option value="Diploma">Diploma</option>
                        <option value="Bachelor's Degree">Bachelor's Degree</option>
                        <option value="Master's Degree">Master's Degree</option>
                        <option value="PhD">Ph.D</option>
                    </select>
                    {errors.highestQualification && <div style={errorStyle}>{errors.highestQualification}</div>}
                </div>

                <div className="mb-3">
                    <label style={labelStyle}>Course Name:</label><span className="text-danger" style={{ verticalAlign: 'middle', fontSize: '1.5em', color: '#e74c3c', fontWeight: 'bold', marginLeft: '5px' }}>*</span>
                    <select
                        name="courseName"
                        className="form-select"
                        value={form.courseName}
                        onChange={handleInputChange}
                        disabled={
                            form.highestQualification === "High School" ||
                            form.highestQualification === "Intermediate"
                        }
                    >
                        <option value="">Select</option>
                        {form.highestQualification === "Diploma" && (
                            <>
                                <option value="Diploma in Computer Science">Diploma in Computer Science</option>
                                <option value="Diploma in Networking">Diploma in Networking</option>
                                <option value="Diploma in Web Development">Diploma in Web Development</option>
                                <option value="Diploma in Data Science">Diploma in Data Science</option>
                                <option value="Diploma in Cyber Security">Diploma in Cyber Security</option>
                                <option value="Others">Others</option>
                            </>
                        )}
                        {form.highestQualification === "Bachelor's Degree" && (
                            <>
                                <option value="B.Tech">B.Tech</option>
                                <option value="B.Tech">B.C.A</option>
                                <option value="B.Sc in Computer Science">B.Sc in Computer Science</option>
                                <option value="B.Com in IT">B.Com in IT</option>
                                <option value="B.A in Data Analysis">B.A in Data Analysis</option>
                                <option value="Others">Others</option>
                            </>
                        )}
                        {form.highestQualification === "Master's Degree" && (
                            <>
                                <option value="M.Tech">M.Tech</option>
                                <option value="M.Sc in AI">M.Sc in AI</option>
                                <option value="M.Com in IT Management">M.Com in IT Management</option>
                                <option value="MBA in IT">MBA in IT</option>
                                <option value="MCA">MCA</option>
                                <option value="Others">Others</option>
                            </>
                        )}
                        {form.highestQualification === "PhD" && (
                            <>
                                <option value="Ph.D in Computer Science">Ph.D in Computer Science</option>
                                <option value="Ph.D in AI and ML">Ph.D in AI and ML</option>
                                <option value="Ph.D in Cyber Security">Ph.D in Cyber Security</option>
                                <option value="Ph.D in Data Science">Ph.D in Data Science</option>
                                <option value="Ph.D in Networking">Ph.D in Networking</option>
                                <option value="Others">Others</option>
                            </>
                        )}
                    </select>
                    {(form.highestQualification === "High School" ||
                        form.highestQualification === "Intermediate") && (
                            <div style={errorStyle}>Higher qualification is required to apply.</div>
                        )}
                    {errors.courseName && <div style={errorStyle}>{errors.courseName}</div>}
                </div>

                {/* Input Field for Custom Course Name */}
                {form.courseName === "Others" && (
                    <div className="mb-3">
                        <label style={labelStyle}>Specify Course Name:</label><span className="text-danger" style={{ verticalAlign: 'middle', fontSize: '1.5em', color: '#e74c3c', fontWeight: 'bold', marginLeft: '5px' }}>*</span>
                        <input
                            type="text"
                            name="customCourseName"
                            className="form-control"
                            value={form.customCourseName}
                            onChange={(event) => {
                                const { value } = event.target;
                                setForm({ ...form, customCourseName: value });
                            }}
                        />
                        {errors.customCourseName && (
                            <div style={errorStyle}>{errors.customCourseName}</div>
                        )}
                    </div>
                )}

                <div className="mb-3">
                    <label style={labelStyle}>College/University:</label><span className="text-danger" style={{ verticalAlign: 'middle', fontSize: '1.5em', color: '#e74c3c', fontWeight: 'bold', marginLeft: '5px' }}>*</span>
                    <input
                        type="text"
                        name="college"
                        className="form-control"
                        value={form.college}
                        onChange={handleInputChange}
                    />
                    {errors.college && <div style={errorStyle}>{errors.college}</div>}
                </div>

                <div className="mb-3">
                    <label style={labelStyle}>Passout Year:</label><span className="text-danger" style={{ verticalAlign: 'middle', fontSize: '1.5em', color: '#e74c3c', fontWeight: 'bold', marginLeft: '5px' }}>*</span>
                    <select
                        name="passoutYear"
                        className="form-select"
                        value={form.passoutYear}
                        onChange={handleInputChange}
                    >
                        <option value="">Select</option>
                        {Array.from({ length: 21 }, (_, i) => new Date().getFullYear() - 10 + i).map(year => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                    {errors.passoutYear && <div style={errorStyle}>{errors.passoutYear}</div>}
                </div>


                <div className="mb-3">
                    <label style={labelStyle}>How did you hear about us?</label><span className="text-danger" style={{ verticalAlign: 'middle', fontSize: '1.5em', color: '#e74c3c', fontWeight: 'bold', marginLeft: '5px' }}>*</span>
                    <select
                        name="hearAboutUs"
                        className="form-select"
                        value={form.hearAboutUs}
                        onChange={handleInputChange}
                    >
                        <option value="">Select</option>
                        <option value="LinkedIn">LinkedIn</option>
                        <option value="Indeed">Indeed</option>
                        <option value="Glassdoor">Glassdoor</option>
                        <option value="Monster">Monster</option>
                        <option value="CareerBuilder">CareerBuilder</option>
                        <option value="ZipRecruiter">ZipRecruiter</option>
                        <option value="SimplyHired">SimplyHired</option>
                        <option value="AngelList">AngelList</option>
                        <option value="FlexJobs">FlexJobs</option>
                        <option value="Naukri">Naukri</option>
                        <option value="Job.com">Job.com</option>
                        <option value="Snagajob">Snagajob</option>
                        <option value="Upwork">Upwork</option>
                        <option value="Freelancer">Freelancer</option>
                        <option value="WayUp">WayUp</option>
                        <option value="Employee Referral">Employee Referral</option>
                        <option value="Other">Other</option>
                    </select>
                    {errors.hearAboutUs && <div style={errorStyle}>{errors.hearAboutUs}</div>}
                </div>

                {
                    // Custom Hear About US 
                    form.hearAboutUs === 'Other' &&
                    (
                        <div className="mb-3">
                            <label style={labelStyle}>Specify Name:</label><span className="text-danger" style={{ verticalAlign: 'middle', fontSize: '1.5em', color: '#e74c3c', fontWeight: 'bold', marginLeft: '5px' }}>*</span>
                            <input
                                type="text"
                                name="customPortalName"
                                className="form-control"
                                value={form.customPortalName}
                                onChange={(event) => {
                                    const { value } = event.target;
                                    setForm({ ...form, customPortalName: value });
                                }}
                            />
                            {errors.customPortalName && (
                                <div style={errorStyle}>{errors.customPortalName}</div>
                            )}
                        </div>
                    )
                }

                {
                    // Employee Id 
                    form.hearAboutUs === 'Employee Referral' &&
                    (
                        <div className="mb-3">
                            <label style={labelStyle}>Specify Employee ID:</label><span className="text-danger" style={{ verticalAlign: 'middle', fontSize: '1.5em', color: '#e74c3c', fontWeight: 'bold', marginLeft: '5px' }}>*</span>
                            <input
                                type="text"
                                name="employeeReferalID"
                                className="form-control"
                                value={form.employeeReferalID}
                                onChange={(event) => {
                                    const { value } = event.target;
                                    setForm({ ...form, employeeReferalID: value });
                                }}
                            />
                            {errors.employeeReferalID && (
                                <div style={errorStyle}>{errors.employeeReferalID}</div>
                            )}
                        </div>
                    )
                }

                <ConsentDiv onConsent={setIsConsentGiven} />
                <div className="text-center">
                    <button type="submit" className="btn btn-primary" style={{ backgroundColor: "orange", border: "none" }}
                        disabled={resumeFileURL === ''}
                    >
                        {isSubmitting ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        ) : (
                            'Submit'
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default JobApplicationForm;
