import React, { useEffect, useState } from 'react'
import axios from 'axios'
import quationQ from '../Images/quationQ.png'
import { useNavigate } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from 'react-bootstrap'; // Import Tooltip and OverlayTrigger from React Bootstrap
import { InfoCircle } from 'react-bootstrap-icons';

const EmployeeDashboard = () => {
  const navigate = useNavigate()
  const [fetchingResult, setFetchingResult] = useState(false);
  const [allSubmission, setAllSubmission] = useState(null);
  const employee_access_token = localStorage.getItem('employee_access_token');
  // Define primary color for inline styles
  const primaryColor = '#FFA500'; // Orange color
  // Fetch submissions from the API
  const readableDateTime = (isoString) => {
    // Create a Date object from the ISO string
    const dateObj = new Date(isoString);

    // Format the Date object to a readable string
    const readableString = dateObj.toLocaleString('en-US', {
      year: 'numeric',     // e.g., "2024"
      month: 'long',       // e.g., "December"
      day: 'numeric',      // e.g., "16"
      hour: '2-digit',     // e.g., "03"
      minute: '2-digit',   // e.g., "30"
      hour12: false         // Use 12-hour clock with AM/PM
    });
    return readableString
  }
  const getAllSubmission = async () => {
    setFetchingResult(true);
    try {
      const config = {
        method: "get",
        url: `${process.env.REACT_APP_UPLOAD_DATA}/employee/${employee_access_token}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        }
      };
      const response = await axios(config);
      if (response.status === 200) {
        setAllSubmission(response.data); // Assuming response contains data as array
        setFetchingResult(false);
      }
    } catch (error) {
      setFetchingResult(false);
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    getAllSubmission();
  }, []);

  // Loader Component
  const Loader = () => (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <div className="spinner-border" style={{ borderTopColor: primaryColor }} role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );

  // UI Component to display submissions
  const SubmissionList = () => (
    <div className="row g-4">
      {allSubmission && allSubmission.length > 0 ? (
        allSubmission.map((submission, index) => (
          <div className="col-lg-5 col-md-6 col-sm-12" key={index}>
            <div className="card shadow-lg border-0 mb-4">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <h5 className="card-title" style={{ color: primaryColor, fontWeight: 'bold' }}>
                    {submission.job_position}
                  </h5>
                  {/* Tooltip Trigger */}
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip
                        id={`tooltip-${index}`}
                        style={{
                          textAlign: 'left',
                          backgroundColor: 'orange',  // Set background color to orange
                          color: 'white',  // Set text color to white
                          maxWidth: '300px',  // Adjust the tooltip width (you can change the value to your liking)
                          whiteSpace: 'normal',  // Ensure the text wraps within the tooltip
                          padding: '10px',  // Optional: Add padding to make the tooltip more readable
                        }}
                      >
                        <div style={{ textAlign: 'left'}}>JD : {submission.job_description}</div>
                        <div style={{ textAlign: 'left'}}>Post: {submission.designation}</div>
                        <div style={{ textAlign: 'left'}}>
                          Experience: {parseInt(submission.relevant_experience_min)} - {parseInt(submission.relevant_experience_max)} years
                        </div>
                      </Tooltip>
                    }
                  >
                    <InfoCircle size={20} className="ms-2" style={{ cursor: 'pointer', color: primaryColor }} />
                  </OverlayTrigger>

                </div>
                <p className="card-text mb-2">
                  <strong>Status: </strong>
                  <span className="text-success">{submission.applicationStatus}</span>
                </p>
                <p className="card-text mb-2">
                  <strong>Job ID: </strong>{submission.job_id}
                </p>
                <p className="card-text mb-2">
                  <strong>Submitted at:</strong> {readableDateTime(submission.submitted_at)}
                </p>
                <p className="card-text mb-0">
                  <strong>Candidate Name: </strong>{submission.referred_candiate_name}
                </p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="col-12">
          <div className="alert alert-info" role="alert">
            No submissions found.
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="container my-5">
      {/* Top Right Logout Button */}
      <button
        className="btn btn-danger position-absolute top-0 end-0 m-4"
        style={{ borderRadius: '50%', padding: '10px 15px' }}
        onClick={() => {
          localStorage.removeItem("employee_access_token");
          navigate('/');
        }}
      >
        <i className="bi bi-box-arrow-right">Logout</i>
      </button>

      {/* Dashboard Header */}
      <section className="text-center mb-5">
        <h2 className="display-4" style={{ color: primaryColor }}>Employee Dashboard</h2>
        <p className="lead text-muted">Here you can find your application status for each job you have applied to.</p>
      </section>

      {/* Submission List */}
      <section className="mb-5">
        {fetchingResult ? <Loader /> : <SubmissionList />}
      </section>

      {/* Footer Section */}
      <section className="text-center mt-5 py-4" style={{ backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)' }}>
        <p className="text-muted" style={{ fontSize: '1.1rem', fontWeight: '500', color: '#6c757d' }}>
          Powered by
          <img
            src={quationQ}
            alt="Company Logo"
            style={{
              height: '40px',
              marginLeft: '12px',
              borderRadius: '50%',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
              transition: 'transform 0.3s ease'
            }}
            className="company-logo"
            onClick={() => {
              localStorage.removeItem("access_token");
              navigate('/');
            }}
          />
          <span style={{ marginLeft: '8px', color: '#343a40', fontWeight: '600' }}>Quation Solution Private Limited</span>
        </p>
        <style>{`
          .company-logo:hover {
            transform: scale(1.1);
          }
        `}</style>
      </section>
    </div>
  );
}

export default EmployeeDashboard