import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import quationQ from '../Images/quationQ.png'
import {useNavigate } from "react-router-dom";
import axios from 'axios';
import { toast } from "react-toastify";

const VendorLogin = () => {
  const [email, setEmail] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({ email: "", vendorId: "", password: "" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  const validateVendorId = (id) => {
    if (!/^QAV\d{4}$/.test(id)) {
      return "Enter Valid Vendor ID";
    }
    return "";
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return "Please enter a valid email address.";
    }
    return "";
  };

  const handleSubmit = async(e) => {
    e.preventDefault();

    // Validate fields
    const emailError = validateEmail(email);
    const vendorIdError = validateVendorId(vendorId);
    const passwordError = password ? "" : "Password is required.";

    setError({ email: emailError, vendorId: vendorIdError, password: passwordError });

    if (emailError || vendorIdError || passwordError) {
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('vendorID', vendorId);
    formData.append('vendorEmail', email);
    formData.append('password', password);
    console.log(formData)
    try {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_UPLOAD_DATA}/vendor/login`,
        headers: {
          Accept: "application/json", // Make sure to set the correct Accept type
          "Content-Type": "application/json",
        },
        data: formData 
      };
      const response = await axios(config);
      // response logic is need to write

    } catch (error) {
      console.log('Error',error)
      setLoading(false)
      if (error.response) {
        // Check if the error has response data
        if (error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.response.data.message);
        }
      } else {
        // If no response, it's a network or unexpected error
        toast.error("Network error! Please check your internet connection.");
      }
    }

  };

  return (
    <div className="container d-flex justify-content-center align-items-center min-vh-100" >
      <div className="card shadow" style={{ width: "40rem", borderRadius: "15px" }}>
        <div className="card-body p-5">
          <div className="text-center mb-4 d-flex align-items-center justify-content-center">
            <img
              src={quationQ}
              alt="Company Logo"
              style={{ width: "60px", height: "60px", cursor: 'pointer' }}
              onClick={() => {
                navigate('/')
              }}
            />
            <h3 style={{ color: "#ff8c00" }}>uation Solution Private Limited</h3>
          </div>
          <hr style={{ borderTop: '2px solid black', margin: '20px 0' }} />
          <h4 className="text-center mb-4" style={{ color: "#ff8c00" }}>Vendor Login</h4>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                id="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
              {error.email && <div className="text-danger mt-1">{error.email}</div>}
            </div>
            <div className="mb-3">
              <label htmlFor="vendorId" className="form-label">
                Vendor ID
              </label>
              <input
                type="text"
                id="vendorId"
                className="form-control"
                value={vendorId}
                onChange={(e) => {
                  setVendorId(e.target.value);
                  setError((prev) => ({ ...prev, vendorId: validateVendorId(e.target.value) }));
                }}
                placeholder="Enter your Vendor ID"
                required
              />
              {error.vendorId && <div className="text-danger mt-1">{error.vendorId}</div>}
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                id="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                required
              />
              {error.password && <div className="text-danger mt-1">{error.password}</div>}
            </div>
            {Object.values(error).some((err) => err) && (
              <div className="alert alert-danger mt-2">Please fix the errors above before submitting.</div>
            )}
            <div className="d-grid">
              <button
                type="submit"
                className="btn btn-primary"
                style={{ backgroundColor: "#ff8c00", borderColor: "#ff8c00" }}
                disabled={loading}
              >
                {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Login"}
              </button>
            </div>
            <small className="text-danger mt-2">** To Redirect to Home Page. Click On Logo</small>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VendorLogin;
