import "./App.css";
import RenderOnAnonymous from "./KC-Auth/RenderOnAnonymous";
import RenderOnAuthenticated from "./KC-Auth/RenderOnAuthenticated";
import Navbar from "./Components/Navbar";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { Provider } from "react-redux";
import store from "./Redux/Store/store";
import Home from "./Components/Home.jsx";
import Dashboard from "./Components/Dashboard.jsx"
import Support from "./Components/Support.jsx"
import { BrowserRouter, Route, Routes } from "react-router-dom";
import JobPosting from "./Components/JobPosting.jsx";
import ReportingManagerMaster from "./Components/ReportingManagerMaster.jsx";
import HiringManagerMaster from "./Components/HiringManagerMaster.jsx";
import SkillsetMaster from "./Components/SkillsetMaster.jsx";
import JobPositionMaster from "./Components/JobPositionMaster.jsx";
import RecruitmentManagerMaster from "./Components/RecruitmentManagerMaster.jsx";
import VendorMaster from "./Components/VendorMaster.jsx";
import StatusMaster from "./Components/StatusMaster.jsx";
import ClientMaster from "./Components/ClientMaster.jsx";
import DepartmentMaster from "./Components/DepartmentMaster.jsx";
import EmployeeMaster from "./Components/EmployeeMaster.jsx";
import JobPostingApproval from "./Components/JobPostingApproval.jsx";
import UserService from "./services/UserService.js";
import DashboardApprover from "./Components/DashboardApprover.jsx";
import DashboardRH from "./Components/DashboardRH.jsx";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RecruitProLandingPage from "./Components/LandingPageComponents/RecruitProLandingPage.jsx";
import LoginSignup from "./Components/OuterCandidateComponents/Login_Signup_page.jsx";
import VerifyEmailPage from "./Components/OuterCandidateComponents/VerifyEmailPage.jsx";
import useAuth from "./Components/OuterCandidateComponents/useAuth.jsx";
import CandidateApplicationForm from "./Components/OuterCandidateComponents/CandidateApplication.jsx";
import ProtectedRoute from "./Components/OuterCandidateComponents/PrivateRoute.jsx";
import PrivateRoute from "./Components/OuterCandidateComponents/PrivateRoute.jsx";
import EmployeeLogin from "./Components/EmployeeReferal/EmployeeLogin.jsx";
import EmployeeProtectedRoute from "./Components/EmployeeReferal/EmployeeProtectedRoute.jsx";
import EmployeeApplicationForm from "./Components/EmployeeReferal/EmployeeApplicationForm.jsx";
import CandidateDashboard from "./Components/OuterCandidateComponents/CandidateDashboard.jsx";
import EmployeeDashboard from "./Components/EmployeeReferal/EmployeeDashboard.jsx";
import VendorLogin from "./Components/Vendor/VendorLogin.jsx";
import RecruitmentAssignee from "./Components/RecruitmentAssignee.jsx";
import VerticalHeadMaster from "./Components/VerticalHeadMaster.jsx";
import ResetPasswordpage from "./Components/OuterCandidateComponents/ResetPasswordpage.jsx";
import RecruitmentHeadNotificationComponent from "./Components/RecruitmentHeadNotificationComponent.jsx";
const { REACT_APP_ADMIN_USER } = process.env
const APPROVER_USERS = JSON.parse(process.env.REACT_APP_APPROVER_USERS || '[]');

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <ReactNotifications />
          <ToastContainer />

          {/* Render routes for authenticated users */}
          <RenderOnAuthenticated>
            <Navbar /> {/* This will only show for authenticated users */}
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/jobposting" element={<JobPosting />} />
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/notification" element={<RecruitmentHeadNotificationComponent />} />
              <Route exact path="/approvals" element={<DashboardApprover />} />
              <Route exact path="/support" element={<Support />} />

              {UserService.getUsername() === process.env.REACT_APP_ADMIN_USER && (
                <>
                  <Route exact path="/reportingmanagermaster" element={<ReportingManagerMaster />} />
                  <Route exact path="/hiringmanagermaster" element={<HiringManagerMaster />} />
                  <Route exact path="/employeemaster" element={<EmployeeMaster />} />
                  <Route exact path="/skillsetmaster" element={<SkillsetMaster />} />
                  <Route exact path="/jobpositionmaster" element={<JobPositionMaster />} />
                  <Route exact path="/recruitmentmanagermaster" element={<RecruitmentManagerMaster />} />
                  <Route exact path="/verticalhead" element={<VerticalHeadMaster />} />
                  <Route exact path="/recruitassigneemaster" element={<RecruitmentAssignee />} />
                  <Route exact path="/vendormaster" element={<VendorMaster />} />
                  <Route exact path="/statusmaster" element={<StatusMaster />} />
                  <Route exact path="/clientmaster" element={<ClientMaster />} />
                  <Route exact path="/departmentmaster" element={<DepartmentMaster />} />
                </>
              )}
              <Route exact path="/approvaljobposting/:id" element={<JobPostingApproval />} />
            </Routes>
          </RenderOnAuthenticated>

          {/* Render routes for anonymous users */}

          <RenderOnAnonymous>
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<RecruitProLandingPage />} />
              <Route path="/administration" element={<Home />} />
              <Route path="/candidate/login" element={<LoginSignup />} />
              <Route path="/verify/quation/:token" element={<VerifyEmailPage />} />
              <Route path="/resetpassword/:email/:token" element={<ResetPasswordpage />} />
              <Route path="/employee/login" element={<EmployeeLogin />} />
              <Route path="/vendor/login" element={<VendorLogin />} />

              {/*Candidate Protected Routes */}
              <Route
                path="/candidate/applicationForm"
                element={
                  <PrivateRoute>
                    <CandidateApplicationForm />
                  </PrivateRoute>
                }
              />
              <Route
                path="/candidate/dashboard"
                element={
                  <PrivateRoute>
                    <CandidateDashboard />
                  </PrivateRoute>
                }
              />
              {/*Employee Protected Routes */}
              <Route
                path="/employee/applicationForm"
                element={
                  <EmployeeProtectedRoute>
                    <EmployeeApplicationForm/>
                  </EmployeeProtectedRoute>
                }
              />
              <Route
                path="/employee/dashboard"
                element={
                  <EmployeeProtectedRoute>
                    <EmployeeDashboard/>
                  </EmployeeProtectedRoute>
                }
              />
            </Routes>
          </RenderOnAnonymous>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
