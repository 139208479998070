import React, { useRef, useState, useEffect } from "react";
import "./LoginSignup.css";
import quationQ from '../Images/quationQ.png'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


const LoginSignup = () => {
    const navigate = useNavigate()
    const formContainer = useRef()
    const [isLogin, setIsLogin] = useState(true);
    const [isRegistering, setIsRegistereing] = useState(false)
    const [formErrors, setFormErrors] = useState({});
    const [loginFormErrors, setLoginFormErrors] = useState({});
    const [passwordStrength, setPasswordStrength] = useState('');
    const [signupformData, setSignupFormData] = useState({
        email: '', firstName: '', middleName: '', lastName: '', aadhar_num: '', mobile: '', password: '', confirmPassword: ''
    })
    const [loginFormData, setLoginFormData] = useState({
        email: '',
        password: '',
    });
    const [resetPasswordBtnClicked, setResetPasswordBtnClicked] = useState(false)
    const [modalVisible, setModalVisible] = useState(false);
    const [resetPasswordFormData, setResetPasswordFormData] = useState({
        "email": '', "aadhar": ''
    })
    const [resetPasswordFormErrors, setResetPasswordFormErrors] = useState({});
    const [isValidating, setIsValidating] = useState(false);


    const handleLoginFormInputs = (e) => {
        const { name, value } = e.target;
        setLoginFormData({ ...loginFormData, [name]: value });
    };

    const validateLoginForm = () => {
        const errors = {};

        // Email Validation
        if (!loginFormData.email || !/\S+@\S+\.\S+/.test(loginFormData.email)) {
            errors.email = "Please enter a valid email address.";
        }

        // Password Validation
        if (!loginFormData.password || loginFormData.password.length < 6) {
            errors.password = "Password must be at least 6 characters long.";
        }

        setLoginFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSignupFormInputs = (e) => {
        const { name, value } = e.target
        setSignupFormData({ ...signupformData, [name]: value })
        // Check password strength when user is typing the password
        if (name === 'password') {
            validatePasswordStrength(value);
        }
    };

    const validatePasswordStrength = (password) => {
        const strength = getPasswordStrength(password);
        setPasswordStrength(strength);
    };

    const getPasswordStrength = (password) => {
        // Basic password strength criteria
        const lengthCriteria = password.length >= 6;
        const hasUpper = /[A-Z]/.test(password);
        const hasLower = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        if (lengthCriteria && hasUpper && hasLower && hasNumber && hasSpecial) {
            return 'strong';
        } else if (lengthCriteria && ((hasUpper && hasLower) || (hasNumber && hasSpecial))) {
            return 'medium';
        } else {
            return 'weak';
        }
    }

    const validateForm = () => {
        const errors = {};

        // Email Validation
        if (!signupformData.email || !/\S+@\S+\.\S+/.test(signupformData.email)) {
            errors.email = "Please enter a valid email address.";
        }

        // Name Validations
        if (!signupformData.firstName) {
            errors.firstName = "First Name is required.";
        }

        if (!signupformData.lastName) {
            errors.lastName = "Last Name is required.";
        }

        // Aadhar Number Validation (12 digits)
        if (!signupformData.aadhar_num || signupformData.aadhar_num.length !== 12 || isNaN(signupformData.aadhar_num)) {
            errors.aadhar_num = "Aadhar Number must be 12 digits.";
        }

        // Mobile Number Validation (Indian mobile format)
        if (!signupformData.mobile || !/^[6-9]\d{9}$/.test(signupformData.mobile)) {
            errors.mobile = "Please enter a valid mobile number.";
        }

        // Password Validation
        if (!signupformData.password || signupformData.password.length < 6) {
            errors.password = "Password must be at least 6 characters long.";
        }

        // Confirm Password Validation
        if (signupformData.password !== signupformData.confirmPassword) {
            errors.confirmPassword = "Passwords do not match.";
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSignUpSubmit = async (e) => {
        e.preventDefault();
        setIsRegistereing(true)
        if (validateForm()) {
            try {
                const config = {
                    method: "post",
                    url: `${process.env.REACT_APP_UPLOAD_DATA}/signup`,  // Make sure REACT_APP_UPLOAD_DATA is defined in .env
                    headers: {
                        Accept: "application/json",  // Expect JSON response
                        "Content-Type": "application/json", // Send data as JSON
                    },
                    data: signupformData // Ensure this is a valid object
                };

                // Send the request and await the response
                const response = await axios(config);
                console.log(response);

                // Check for different response statuses
                if (response.status === 401) {
                    // In case of 401 Unauthorized, handle the error (like incorrect credentials or email not verified)
                    toast.error(response.data.message);
                    setIsRegistereing(false);
                } else if (response.data.status) {
                    // If the response has status true, show success
                    toast.success(response.data.message);
                    setIsLogin(true);
                    setIsRegistereing(false);
                }
            } catch (error) {
                // Catching error if the request fails (like network issues or server errors)
                if (error.response) {
                    // Check if the error has response data
                    if (error.response.data && error.response.data.message) {
                        toast.error(error.response.data.message);
                        setIsRegistereing(false);
                    } else {
                        toast.error("Something went wrong! Please try again later.");
                        setIsRegistereing(false);
                    }
                } else {
                    // If no response, it's a network or unexpected error
                    toast.error("Network error! Please check your internet connection.");
                    setIsRegistereing(false);
                }
            }
        }
    }

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        if (validateLoginForm()) {
            e.preventDefault();
            setIsRegistereing(true)
            try {
                const config = {
                    method: "post",
                    url: `${process.env.REACT_APP_UPLOAD_DATA}/login`,  // Make sure REACT_APP_UPLOAD_DATA is defined in .env
                    headers: {
                        Accept: "application/json",  // Expect JSON response
                        "Content-Type": "application/json", // Send data as JSON
                    },
                    data: loginFormData // Ensure this is a valid object
                };
                const response = await axios(config);
                if (response.data.status) {
                    toast.success(response.data.message)
                    setIsRegistereing(false)
                    localStorage.setItem("access_token", response.data.access_token);
                    if (localStorage.getItem('jobId')) {
                        navigate('/candidate/applicationForm')
                    }
                    else {
                        navigate('/candidate/dashboard')
                    }
                }
                else {
                    toast.error(response.data.message)
                    setIsRegistereing(false)
                    setLoginFormData({ email: '', password: '' })
                }

            } catch (error) {
                if (error.response) {
                    // Check if the error has response data
                    if (error.response.data && error.response.data.message) {
                        toast.error(error.response.data.message);
                        setIsRegistereing(false);
                    } else {
                        toast.error("Something went wrong! Please try again later.");
                        setIsRegistereing(false);
                    }
                } else {
                    // If no response, it's a network or unexpected error
                    toast.error("Network error! Please check your internet connection.");
                    setIsRegistereing(false);
                }
            }
        }
    };

    const resetPasswordbtnHandler = async (e) => {
        e.preventDefault()
        setModalVisible(true)
    }

    const closeModal = () => {
        setModalVisible(false);
        setResetPasswordBtnClicked(false)
        setResetPasswordFormData({
            "email": '', "aadhar": ''
        })
    };

    const validateResetPasswordForm = () => {
        const errors = {};

        // Email Validation
        if (!resetPasswordFormData.email || !/\S+@\S+\.\S+/.test(resetPasswordFormData.email)) {
            errors.email = "Please enter resgistered email address.";
        }

        if (!resetPasswordFormData.aadhar || resetPasswordFormData.aadhar.length !== 12 || isNaN(resetPasswordFormData.aadhar)) {
            errors.aadhar = "Aadhar Number must be 12 digits.";
        }
        setResetPasswordFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const sendDetails = async () => {
        if (validateResetPasswordForm()) {
            setIsValidating(true)
            const formData = new FormData()
            formData.append('email', resetPasswordFormData.email)
            formData.append('aadhar', resetPasswordFormData.aadhar)
            try {
                const config = {
                    method: "post",
                    url: `${process.env.REACT_APP_UPLOAD_DATA}/resetPassword`,  // Make sure REACT_APP_UPLOAD_DATA is defined in .env
                    headers: {
                        Accept: "application/json",  // Expect JSON response
                        "Content-Type": "application/json", // Send data as JSON
                    },
                    data: formData // Ensure this is a valid object
                };
                const response = await axios(config);
                if (response.status === 200) {
                    toast.success(response.data)
                    setIsValidating(false)
                    closeModal()
                }
            } catch (error) {
                if (error.response) {
                    // Check if the error has response data
                    if (error.response.data && error.response.status === 404) {
                        toast.error(error.response.data);
                        setIsValidating(false)
                    } else {
                        toast.error("Something went wrong! Please try again later.");
                        setIsValidating(false)
                    }
                } else {
                    // If no response, it's a network or unexpected error
                    toast.error("Network error! Please check your internet connection.");
                    setIsValidating(false)
                }
            }
        }
    };

    // This is used to handle the scroll of the login form if we are in the last of signup form and suddenly switch it up
    useEffect(() => {
        if (isLogin && formContainer.current) {
            formContainer.current.scrollTop = 0; // Scrolls to the top
        }
        setFormErrors({})
        setLoginFormErrors({})
        setPasswordStrength('')
        setLoginFormData({ email: '', password: '' })
        setSignupFormData({ email: '', firstName: '', middleName: '', lastName: '', aadhar_num: '', mobile: '', password: '', confirmPassword: '' })
    }, [isLogin]);


    return (
        <div className="container d-flex justify-content-center align-items-center vh-100">
            {modalVisible && (
                <div
                    className="modal fade show"
                    style={{
                        display: 'block',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 1050,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background overlay
                        backdropFilter: 'blur(10px)', // Apply the blur effect
                        paddingTop: '100px' // Add some padding for the modal to be centered
                    }}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    // Remove aria-hidden="true" and use inert instead
                    // Use inert to prevent focus and hide modal content for assistive technologies
                    {...(modalVisible ? {} : { inert: true })}
                >
                    <div
                        className="modal-dialog"
                        role="document"
                        style={{
                            maxWidth: '500px', // Limiting the max width of the modal
                            margin: 'auto',
                        }}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Reset Password</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={closeModal}
                                    aria-label="Close"
                                    disabled={isValidating}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">
                                        <strong>Email:</strong>
                                    </label>
                                    <input
                                        type="email"
                                        className={`form-control ${resetPasswordFormErrors.email ? 'is-invalid' : ''}`}
                                        name="email"
                                        value={resetPasswordFormData.email}
                                        onChange={(e) =>
                                            setResetPasswordFormData({
                                                ...resetPasswordFormData,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                        disabled={isValidating}
                                    />
                                    {resetPasswordFormErrors.email && <div className="invalid-feedback">{resetPasswordFormErrors.email}</div>}
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="aadhar" className="form-label">
                                        <strong>Aadhar:</strong>
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${resetPasswordFormErrors.aadhar ? 'is-invalid' : ''}`}
                                        name="aadhar"
                                        value={resetPasswordFormData.aadhar}
                                        onChange={(e) =>
                                            setResetPasswordFormData({
                                                ...resetPasswordFormData,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                        disabled={isValidating}
                                    />
                                    {resetPasswordFormErrors.aadhar && <div className="invalid-feedback">{resetPasswordFormErrors.aadhar}</div>}
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={closeModal} disabled={isValidating}>
                                    Close
                                </button>
                                <button type="button" className="btn btn-primary" onClick={sendDetails}>
                                    {isValidating ? (
                                        <div className="spinner-border text-light" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    ) : (
                                        'Validate'
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="card p-4 shadow-lg login-signup-card">
                <div className="logo-container d-flex align-items-center justify-content-center">
                    <a onClick={() => {
                        localStorage.removeItem('access_token')
                        localStorage.removeItem('employee_access_token')
                        localStorage.removeItem('jobId')
                        navigate('/')
                    }}>
                        <img
                            src={quationQ}
                            alt="Company Logo"
                            className="logo-img img-fluid me-3"
                        />
                    </a>
                    <h1 className="company-name">uation Solution Private Limited</h1>
                </div>

                <div className="d-flex justify-content-end mt-2">
                    {
                        isRegistering ? (
                            // Disable both buttons if registering is true
                            <>
                                {
                                    isLogin
                                        ?
                                        <p className="mb-0 clickable-text" style={{ pointerEvents: 'none', opacity: 0.5 }}>
                                            Sign-Up
                                        </p>
                                        :
                                        <p className="mb-0 clickable-text" style={{ pointerEvents: 'none', opacity: 0.5 }}>
                                            Login
                                        </p>
                                }
                            </>
                        ) : isLogin ? (
                            // Show the Login Page link
                            <p
                                className="mb-0 clickable-text"
                                onClick={() => setIsLogin(false)}
                            >
                                Sign-Up
                            </p>
                        ) : (
                            // Show the New Candidate link
                            <p
                                className="mb-0 clickable-text"
                                onClick={() => setIsLogin(true)}
                            >
                                Login
                            </p>
                        )
                    }
                </div>


                <div ref={formContainer} className={`form-container ${isLogin ? "show-login" : "show-signup overflow-y-scroll"}`}>
                    {/* Login Form */}
                    <form className="login-form p-2 mt-5" onSubmit={handleLoginSubmit}>
                        <div className="mb-3">
                            <label htmlFor="loginEmail" className="form-label"><strong>Registered Email <span style={{ color: 'red' }}>*</span>:</strong></label>
                            <input
                                type="email"
                                className={`form-control ${loginFormErrors.email ? 'is-invalid' : ''}`}
                                id="loginEmail"
                                name="email"
                                value={loginFormData.email}
                                onChange={handleLoginFormInputs}
                                placeholder="example123@example.com"
                                disabled={resetPasswordBtnClicked}
                            />
                            {loginFormErrors.email && <div className="invalid-feedback">{loginFormErrors.email}</div>}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="loginPassword" className="form-label"><strong>Password <span style={{ color: 'red' }}>*</span>:</strong></label>
                            <input
                                type="password"
                                className={`form-control ${loginFormErrors.password ? 'is-invalid' : ''}`}
                                id="loginPassword"
                                name="password"
                                value={loginFormData.password}
                                onChange={handleLoginFormInputs}
                                placeholder="Enter Your Password"
                                disabled={resetPasswordBtnClicked}
                            />
                            {loginFormErrors.password && <div className="invalid-feedback">{loginFormErrors.password}</div>}
                        </div>

                        <div className="d-flex mt-4">
                            <button type="submit" className="btn btn-orange w-50" disabled={resetPasswordBtnClicked}>
                                Login
                            </button>
                            <button
                                className="btn btn-primary w-50 ms-2"
                                onClick={resetPasswordbtnHandler}
                            >
                                {resetPasswordBtnClicked ? (
                                    <div className="spinner-border text-light" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                ) : (
                                    'Reset Password'
                                )}
                            </button>
                        </div>
                    </form>

                    {/* Signup Form */}
                    <form className="signup-form" onSubmit={handleSignUpSubmit}>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label"><strong>Candidate Email <span style={{ color: 'red' }}>*</span>:</strong></label>
                            <input
                                type="email"
                                name="email"
                                value={signupformData.email}
                                onChange={handleSignupFormInputs}
                                className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}
                                id="email"
                            />
                            {formErrors.email && <div className="invalid-feedback">{formErrors.email}</div>}
                        </div>

                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label htmlFor="firstName" className="form-label"><strong>First Name <span style={{ color: 'red' }}>*</span>:</strong></label>
                                <input
                                    type="text"
                                    name="firstName"
                                    value={signupformData.firstName}
                                    onChange={handleSignupFormInputs}
                                    className={`form-control ${formErrors.firstName ? 'is-invalid' : ''}`}
                                    id="firstName"
                                />
                                {formErrors.firstName && <div className="invalid-feedback">{formErrors.firstName}</div>}
                            </div>

                            <div className="col-md-4 mb-3">
                                <label htmlFor="middleName" className="form-label"><strong>Middle Name:</strong></label>
                                <input
                                    type="text"
                                    name="middleName"
                                    value={signupformData.middleName}
                                    onChange={handleSignupFormInputs}
                                    className="form-control"
                                    id="middleName"
                                />
                            </div>

                            <div className="col-md-4 mb-3">
                                <label htmlFor="lastName" className="form-label"><strong>Last Name <span style={{ color: 'red' }}>*</span>:</strong></label>
                                <input
                                    type="text"
                                    name="lastName"
                                    value={signupformData.lastName}
                                    onChange={handleSignupFormInputs}
                                    className={`form-control ${formErrors.lastName ? 'is-invalid' : ''}`}
                                    id="lastName"
                                />
                                {formErrors.lastName && <div className="invalid-feedback">{formErrors.lastName}</div>}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="aadhar" className="form-label"><strong>Aadhar Number <span style={{ color: 'red' }}>*</span>:</strong></label>
                            <input
                                type="text"
                                name="aadhar_num"
                                value={signupformData.aadhar_num}
                                onChange={handleSignupFormInputs}
                                className={`form-control ${formErrors.aadhar_num ? 'is-invalid' : ''}`}
                                id="aadhar"
                            />
                            {formErrors.aadhar_num && <div className="invalid-feedback">{formErrors.aadhar_num}</div>}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="mobile" className="form-label"><strong>Mobile Number <span style={{ color: 'red' }}>*</span>:</strong></label>
                            <input
                                type="tel"
                                name="mobile"
                                value={signupformData.mobile}
                                onChange={handleSignupFormInputs}
                                className={`form-control ${formErrors.mobile ? 'is-invalid' : ''}`}
                                id="mobile"
                            />
                            {formErrors.mobile && <div className="invalid-feedback">{formErrors.mobile}</div>}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="password" className="form-label"><strong>Password <span style={{ color: 'red' }}>*</span>:</strong></label>
                            <input
                                type="password"
                                className={`form-control ${formErrors.password ? 'is-invalid' : ''}`}
                                id="password"
                                name='password'
                                value={signupformData.password}
                                onChange={handleSignupFormInputs}
                            />
                            {formErrors.password && <div className="invalid-feedback">{formErrors.password}</div>}
                            <div
                                className={`password-strength ${passwordStrength}`}
                                style={{
                                    color: passwordStrength === 'weak' ? 'red' : passwordStrength === 'medium' ? 'orange' : 'green',
                                }}
                            >
                                {passwordStrength && (
                                    <small>
                                        Password Strength: {passwordStrength.charAt(0).toUpperCase() + passwordStrength.slice(1)}
                                    </small>
                                )}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="confirmPassword" className="form-label"><strong>Confirm Password <span style={{ color: 'red' }}>*</span>:</strong></label>
                            <input
                                type="password"
                                className={`form-control ${formErrors.confirmPassword ? 'is-invalid' : ''}`}
                                id="confirmPassword"
                                onChange={handleSignupFormInputs}
                                name="confirmPassword"
                                value={signupformData.confirmPassword}
                            />
                            {formErrors.confirmPassword && <div className="invalid-feedback">{formErrors.confirmPassword}</div>}
                        </div>

                        <button type="submit" className="btn btn-orange w-100" disabled={isRegistering}>
                            {isRegistering ? (
                                <div className="spinner-border text-light" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : (
                                'Signup'
                            )}
                        </button>
                    </form>
                </div>


            </div>
        </div>
    );
}
    ;

export default LoginSignup;
