import React, { useState, useEffect, useRef } from 'react'
import FooterPages from './FooterPages';
import UserService from '../services/UserService'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import getNotification from '../Redux/Action/action'
import { toast } from 'react-toastify'
const { REACT_APP_UPLOAD_DATA } = process.env
const { REACT_APP_REDIRECT_URI } = process.env

function VendorMaster() {
  const dispatch = useDispatch()
  // const [spocno, setspocno] = useState([{ no: 0, name: "", phone: 0 }])
  const [filteredvendorlist, setfilteredvendorlist] = useState([])
  const [editobject, seteditobject] = useState({})
  const [vendorlist, setvendorlist] = useState([])
  const [file, setFile] = useState(null); // To store the selected file
  const [isUploading, setIsUploading] = useState(false); // To track if uploading
  const [uploadStatus, setUploadStatus] = useState(null); // To store upload status
  const [isUploaded, setIsUploaded] = useState(false); // To track if file is uploaded
  const fileInputRef = useRef(null);
  const [error, setError] = useState("");
  const [vendorName, setVendorName] = useState('')
  const [vendorEmail, setVendorEmail] = useState('')
  const [msaFileName, setMSAFileName] = useState('')
  const [msaFileURL, setMSAFileURL] = useState('')
  useEffect(() => {
    handlefetch()
  }, [])

  const handlefetch = async () => {
    if (UserService.isLoggedIn()) {
      try {

        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/vendor`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },

        };
        const getResponse = await axios(config);

        if (getResponse.data !== "Invalid User!") {
          setvendorlist(getResponse.data)
          setfilteredvendorlist(getResponse.data)
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/vendormaster`,
        });
      }, 1000);
    }
  };
  // const handlespocadd = () => {
  //   let arr = [...spocno]
  //   arr.push(arr[arr.length] + 1)
  //   setspocno(arr)

  // }
  // const handlespocremove = (removeindex) => {
  //   if (removeindex === 0) {

  //   }
  //   else {
  //     let arr = [...spocno]
  //     arr.pop()
  //     setspocno(arr)

  //   }


  // }
  const handleeditstatus = async (item) => {
    if (UserService.isLoggedIn()) {
      if (true) {
        try {
          const sendData = {
            rec_id: item.rec_id,
            rec_name: item.rec_name,
            rec_email_id: item.rec_email_id,
            status: item.status === true ? false : true,
            created_by: UserService.getUsername()
          };

          const config = {
            method: "put",
            url: `${REACT_APP_UPLOAD_DATA}/recruitment_manager`,
            headers: {
              Accept: "application/json",  // API expects a JSON response
              "Content-Type": "application/json",  // Send data as JSON
            },
            data: JSON.stringify(sendData), // Convert your object to a JSON string
          };
          const getResponse = await axios(config);
          console.log(getResponse);
          if (getResponse.data !== "Invalid User!") {
            // setbrandoptions(getResponse.data);
            // setselectedbrand(getResponse.data[0].brand);
            handlefetch()
            document.getElementById("closemodal2").click()
            seteditobject({ rec_id: "", rec_email_id: "", rec_name: "", status: "Active" })
            dispatch(
              getNotification({
                message: "Recruitment Manager successfully edited",
                type: "success",
              })
            );
          }
          else {
            UserService.doLogin({
              redirectUri: `${REACT_APP_REDIRECT_URI}/vendormaster`,
            });
          }
        } catch (err) {
          console.log("Server Error", err);
          if (err.response && err.response.status === 500) {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 400) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 422) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 404) {
            dispatch(
              getNotification({
                message: "Page not Found",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 401) {
            dispatch(
              getNotification({
                message: "Session expired! Please log in again",
                type: "default",
              })
            );
            setTimeout(UserService.doLogin(), 1000)
          } else {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          }
        }
      }
      else {
        dispatch(
          getNotification({
            message: "Please fill all entries",
            type: "default",
          })
        );
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/vendormaster`,
        });
      }, 1000);
    }
  }

  const handlesearch = (value) => {
    if (value?.length > 3) {
      setfilteredvendorlist(vendorlist.filter((item) =>
        item.rm_name.toLowerCase().includes(value.toLowerCase())
      ))

    }
    else {
      setfilteredvendorlist(vendorlist)
    }
  }

  // File change handler
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    // Check if a file was selected
    if (selectedFile) {
      // Restrict file size to 20MB (5 * 1024 * 1024)
      const maxSize = 20 * 1024 * 1024; // 20MB
      if (selectedFile && selectedFile.type === 'application/pdf') {
        if (selectedFile.size > maxSize) {
          setError("File size exceeds 20MB. Please upload a smaller file.");
          setFile(null); // Reset file selection
        } else {
          setFile(selectedFile);
          setError(""); // Clear error if file is valid
        }
      } else {
        alert('Please select a valid PDF file');
      }
    }
  }

  // File upload handler
  const handleFileUpload = async () => {
    if (!file) return;
    setIsUploading(true);
    setUploadStatus('Uploading...');
    const formData = new FormData()
    formData.append('file', file)
    try {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_UPLOAD_DATA}/msa_upload`,  // Make sure REACT_APP_UPLOAD_DATA is defined in .env
        headers: {
          Accept: "application/json",  // Expect JSON response
          'Content-Type': 'multipart/form-data', // Send data as JSON
        },
        data: formData // Ensure this is a valid object
      };
      // Send the request and await the response
      const response = await axios(config);
      if (response.status === 200) {
        setMSAFileName(response.data.file_name)
        setMSAFileURL(response.data.file_url)
        setIsUploading(false);
        setUploadStatus('Uploaded Successful');
        setIsUploaded(true)
      }
    } catch (error) {
      if (error.response) {
        // Check if the error has response data
        if (error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
          setIsUploading(false);
          setUploadStatus('Some Error Occured');
        } else {
          toast.error("Something went wrong! Please try again later.");
          setIsUploading(false);
          setUploadStatus('Some Error Occured');
        }
      } else {
        // If no response, it's a network or unexpected error
        toast.error("Network error! Please check your internet connection.");
        setIsUploading(false);
        setUploadStatus('Some Error Occured');
      }
    }
  };

  // Delete file handler
  const handleDeleteFile = async (e) => {
    e.preventDefault();
    try {
      const config = {
        method: "delete",
        url: `${process.env.REACT_APP_UPLOAD_DATA}/msa_file/${msaFileName}`,
      };
      // Send the request and await the response
      const response = await axios(config);
      if (response.status === 200) {
        toast.success(response.data.message)
        setMSAFileName('')
        setMSAFileURL('')
        setFile(null);
        setIsUploaded(false);
        setUploadStatus(null);
        // Reset the file input to allow for a new file to be selected
        if (fileInputRef.current) {
          fileInputRef.current.value = '';  // Reset the input value
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        // Handle errors
        if (error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong! Please try again later.");
        }
      } else {
        toast.error("Network error! Please check your internet connection.");
      }
    }
  };

  // add vendor
  const handleAddVendor = async (e) => {
    e.preventDefault()
    if (!vendorName || !vendorEmail) {
      setError("Please fill in all required fields.");
      return;
    }
    else {
      const vendorFormData = new FormData()
      vendorFormData.append("vendor_name", vendorName)
      vendorFormData.append("vendor_email", vendorEmail)
      vendorFormData.append("msa_link", msaFileURL)
      vendorFormData.append("msa_file_name", msaFileName)
      vendorFormData.append("created_by", UserService.getUsername())
      try {
        const config = {
          method: "post",
          url: `${process.env.REACT_APP_UPLOAD_DATA}/vendor`,
          headers: {
            Accept: "application/json",
            'Content-Type': 'application/json',
          },
          data: vendorFormData
        };
        const response = await axios(config)
        if (response.status === 200) {
          toast.success(response.data)
          setVendorEmail('')
          setVendorName('')
          setMSAFileName('')
          setMSAFileURL('')
          setFile(null);
          setIsUploaded(false);
          setUploadStatus(null);
          // Reset the file input to allow for a new file to be selected
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
          document.getElementById('closemodal').click()
          handlefetch()
        }
      } catch (error) {
        if (error.response) {
          // Check if the error has response data
          if (error.response.data && error.response.status === 401) {
            toast.error(error.response.data);
          } else {
            toast.error("Something went wrong! Please try again later.");
          }
        } else {
          // If no response, it's a network or unexpected error
          toast.error("Network error! Please check your internet connection.");
        }
      }
    }
  }

  {/* <li className='my-2' id="spoc">
                  <label className=''>Vendor SPOC</label>
                  <i className='mt-2 fa fa-plus float-end' onClick={() => handlespocadd()} />
                  {spocno?.map((item, index) => {
                    return <div className='row' >

                      <div className='d-flex justify-content-between'>
                        <label>Spoc No.{index + 1}  </label>
                        {spocno?.length === index + 1 && index !== 0 && <i className='my-auto fa fa-minus ' onClick={() => handlespocremove(index)} />}
                      </div>

                      <div className='col-sm d-flex'>
                        <label className='my-auto mx-2'>  Name </label>
                        <input className="form-control" placeholder='SPOC Name' />
                      </div>
                      <div className='col-sm d-flex'>
                        <label className='my-auto mx-2'>Phone </label>
                        <input className="form-control" placeholder='SPOC Phone' />

                      </div>
                    </div>
                  })}
                </li> */}
  return (
    <>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: "#fafafa" }}>
              <h6 className="modal-title orangetheme" id="exampleModalLabel">
                Add New Vendor
              </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closemodal"
                disabled={msaFileURL !== ''}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body py-3 px-4">
              <ol type="1">
                <li className="my-3">
                  <div className="row">
                    <label className="col-sm-4 col-form-label">Vendor Name</label>
                    <div className="col-sm-8">
                      <input
                        className={`form-control ${error && !vendorName ? "is-invalid" : ""}`}
                        placeholder="Vendor Name"
                        value={vendorName}
                        onChange={(e) => setVendorName(e.target.value)}
                      />
                      {error && !vendorName && <div className="invalid-feedback">Vendor name is required</div>}
                    </div>
                  </div>
                </li>

                <li className="my-3">
                  <div className="row">
                    <label className="col-sm-4 col-form-label">Vendor Email</label>
                    <div className="col-sm-8">
                      <input
                        type='email'
                        className={`form-control ${error && !vendorEmail ? "is-invalid" : ""}`}
                        placeholder="Vendor Email"
                        value={vendorEmail}
                        onChange={(e) => setVendorEmail(e.target.value)}
                      />
                      {error && !vendorEmail && <div className="invalid-feedback">Vendor email is required</div>}
                    </div>
                  </div>
                </li>

                <li className="my-3">
                  <div className="row d-flex flex-wrap align-items-center gap-3">
                    <label className="col-sm-4 col-form-label" htmlFor="file-input">
                      MSA Document (pdf only):
                    </label>
                    {error && <p style={{ color: "red" }}>{error}</p>}

                    <div className="col-sm-8">
                      <input
                        type="file"
                        name="resume"
                        className="form-control"
                        accept=".pdf"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                        id="file-input"
                        ref={fileInputRef}
                      />
                      {!isUploaded && (
                        <label htmlFor="file-input" className="btn btn-primary">
                          Select File
                        </label>
                      )}

                      {file && !isUploaded && (
                        <input
                          type="text"
                          value={file.name}
                          className="form-control"
                          readOnly
                          style={{ width: "250px" }}
                        />
                      )}

                      {file && !isUploaded && (
                        <button
                          className="btn btn-success"
                          onClick={handleFileUpload}
                          disabled={isUploading || !file}
                        >
                          {isUploading ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Upload"
                          )}
                        </button>
                      )}

                      {isUploaded && (
                        <>
                          <input
                            type="text"
                            value={file.name}
                            className="form-control"
                            readOnly
                            style={{ width: "250px" }}
                          />
                          <button className="btn btn-danger" onClick={handleDeleteFile}>
                            Delete
                          </button>
                        </>
                      )}

                      {uploadStatus && <div className="text-info mt-2">{uploadStatus}</div>}
                    </div>
                  </div>
                </li>
              </ol>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger btn-sm"
                data-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault()
                  setFile('')
                  setIsUploaded(false)
                  setVendorName('')
                  setVendorEmail('')
                }}
                disabled={msaFileURL !== ''}
              >
                CLOSE
              </button>
              <button
                type="button"
                className="btn btn-success btn-sm"
                onClick={(e) => handleAddVendor(e)}
                disabled={msaFileURL === ''}
              >
                ADD
              </button>
            </div>
          </div>
        </div>
      </div>


      <div>
        <div className='container  masterhomepage'  >
          <div className='p-3'>
            <h4 className='my-2 orangetheme'> Vendor Master</h4>
            <div className='d-flex flex-row-reverse my-4'>

              <i class="fa fa-plus-circle mx-1 text-success" style={{ fontSize: "25px" }} data-toggle={"modal"}
                data-target="#exampleModal"></i>
              <div class="input-group input-group-sm  mx-4">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm">Search</span>
                </div>
                <input type="text" placeholder='Type atleast 3 characters to search' class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={(e) => { handlesearch(e.target.value) }} />
              </div>
            </div>
            {filteredvendorlist?.length > 0 ?
              <table className='table table-striped mt-4 table-sm'>
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Vendor ID</th>
                    <th>Vendor Name</th>
                    <th>Vendor Email</th>
                    <th>Status</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredvendorlist?.map((item, index) => {
                    return <tr>
                      <td>{index + 1}</td>
                      <td>{item.vendor_id}</td>
                      <td>{item.vendor_name}</td>
                      <td>{item.vendor_email}</td>
                      <td>
                        <div class="form-check form-switch">

                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                            checked={item.status === true}
                            onChange={() => handleeditstatus(item)}
                          />
                          <label class="form-check-label" for="flexSwitchCheckChecked">{item.status === true ? "Active" : "Inactive"}</label>
                        </div>
                      </td>
                      <td> <i className='fa fa-edit' onClick={() => { seteditobject(item) }} data-toggle={"modal"}
                        data-target="#exampleModal2"></i></td>

                    </tr>
                  })}

                </tbody>
              </table> : <div>There are currently no records to display!!!</div>}
          </div>
        </div>
      </div>
      <FooterPages />
    </>
  )
}

export default VendorMaster