import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Form, InputGroup, Alert } from 'react-bootstrap';
import quationQ from '../Images/quationQ.png'
import axios from "axios";
import { toast } from "react-toastify";

const ResetPasswordpage = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordStrength, setPasswordStrength] = useState('');
    const [isPasswordMatch, setIsPasswordMatch] = useState(true);
    const [isFormValid, setIsFormValid] = useState(true);

    // Password strength checker
    const checkPasswordStrength = (password) => {
        const strength = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (password.length < 8) {
            setPasswordStrength('Weak');
        } else if (strength.test(password)) {
            setPasswordStrength('Strong');
        } else {
            setPasswordStrength('Moderate');
        }
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        checkPasswordStrength(newPassword);
        validatePasswordMatch(newPassword, confirmPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);
        validatePasswordMatch(password, newConfirmPassword);
    };

    const validatePasswordMatch = (password, confirmPassword) => {
        if (password !== confirmPassword) {
            setIsPasswordMatch(false);
        } else {
            setIsPasswordMatch(true);
        }
    };

    const handleReset = () => {
        setPassword('');
        setConfirmPassword('');
        setPasswordStrength('');
        setIsPasswordMatch(true);
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        if (password && confirmPassword && isPasswordMatch) {
            const formData = new FormData()
            formData.append('token',params.token)
            formData.append('email',params.email)
            formData.append('password',password)
            try {
                const config = {
                    method: "post",
                    url: `${process.env.REACT_APP_UPLOAD_DATA}/validate/resetPassword`,  // Make sure REACT_APP_UPLOAD_DATA is defined in .env
                    headers: {
                        Accept: "application/json",  // Expect JSON response
                        "Content-Type": "application/json", // Send data as JSON
                    },
                    data: formData // Ensure this is a valid object
                };
                const response = await axios(config);
                if(response.status === 200){
                    toast.success(response.data)
                    navigate('/')
                }
            } catch (error) {
                if (error.response) {
                    // Check if the error has response data
                    if (error.response.data && error.response.status === 401) {
                        toast.error(error.response.data);
                        navigate('/')
                    } else {
                        toast.error("Something went wrong! Please try again later.");
                    }
                } else {
                    // If no response, it's a network or unexpected error
                    toast.error("Network error! Please check your internet connection.");
                }
            } 
        } else {
            setIsFormValid(false);
        }
    };

    return (
        <div className="container mt-5">
            <div className="logo-container d-flex align-items-center justify-content-center">
                <a>
                    <img
                        src={quationQ}
                        alt="Company Logo"
                        className="logo-img img-fluid me-3"
                    />
                </a>
                <h1 className="company-name">Quation Solution Private Limited</h1>
            </div>
            <Form onSubmit={handleSubmit} className="shadow-lg p-4 rounded border mt-4">
                <Form.Group controlId="formPassword">
                    <Form.Label>Password</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type="password"
                            placeholder="Enter new password"
                            value={password}
                            onChange={handlePasswordChange}
                            required
                        />
                    </InputGroup>
                    <Form.Text className="text-muted">
                        Password must be at least 8 characters long and contain a mix of letters, numbers, and special characters.
                    </Form.Text>
                    <div className={`mt-2 text-${passwordStrength === 'Strong' ? 'success' : passwordStrength === 'Moderate' ? 'warning' : 'danger'}`}>
                        {passwordStrength && `Password strength: ${passwordStrength}`}
                    </div>
                </Form.Group>

                <Form.Group controlId="formConfirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Confirm your password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        required
                    />
                    {!isPasswordMatch && (
                        <Alert variant="danger" className="mt-2">
                            Passwords do not match.
                        </Alert>
                    )}
                </Form.Group>

                {!isFormValid && (
                    <Alert variant="danger">
                        Please fill out the form correctly.
                    </Alert>
                )}

                <Button
                    type="submit"
                    variant="primary"
                    className="w-100 mt-3"
                    style={{ backgroundColor: '#FFA500', borderColor: '#FFA500' }}
                >
                    Reset Password
                </Button>

                <Button
                    variant="secondary"
                    className="w-100 mt-3"
                    onClick={handleReset}
                >
                    Reset Fields
                </Button>
            </Form>
        </div>
    );
};


export default ResetPasswordpage