import React, { useEffect, useState } from "react";
import axios from 'axios'
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserService from "../services/UserService.js";

import quationQ from './Images/quationQ.png'
import getNotification from "../Redux/Action/action.js";
import { useDispatch } from "react-redux";

const { REACT_APP_REDIRECT_URI } = process.env;
const { REACT_APP_UPLOAD_DATA } = process.env;
const { REACT_APP_ADMIN_USER } = process.env
function Navbar() {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const location = useLocation()
  const [recruitmentHeadList, setRecruitmentHeadList] = useState([])
  // Fteching All recruiter Head
  const fetchingRecrutmentHeads = async () => {
    if (UserService.isLoggedIn()) {
      try {
        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/recruitmenthead`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },

        };
        const getResponse = await axios(config);
        if (getResponse.data !== "Invalid User!") {
          setRecruitmentHeadList(getResponse.data)
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/reporting_managers`,
          });
        }
      } catch (err) {
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
        });
      }, 1000);
    }
  };
  useEffect(() => {
    fetchingRecrutmentHeads()
  }, [])
  return (
    <>
      {location.pathname !== "/" &&
        <nav className="navbar navbar-expand-sm   navbar-light bg-light" style={{ zIndex: 4 }}>
          <a className="navbar-brand " href="/">
            <img src={quationQ} style={{ height: "32px" }} />
            {" "}
            Recruit Pro
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="">
              <div style={{ width: "30px", height: "3px", backgroundColor: "white", margin: "6px" }}></div>
              <div style={{ width: "30px", height: "3px", backgroundColor: "white", margin: "6px" }}></div>
              <div style={{ width: "30px", height: "3px", backgroundColor: "white", margin: "6px" }}></div>
            </span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li
                className={UserService.isLoggedIn() && location.pathname != '/dashboard' ? "nav-item-active ml-3" : "d-none"}
              >
                <a className="nav-link" href="/dashboard">
                  <i className='fab fa-accusoft'></i> Dashboard</a>

              </li>
              <li className={UserService.isLoggedIn() && (UserService.getUsername() === REACT_APP_ADMIN_USER) ? "nav-item dropdown" : "d-none"}>
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="fa fa-gear"></i> Master
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => navigate("/reportingmanagermaster")}>Reporting Manager</a>
                  <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => navigate("/hiringmanagermaster")}>Hiring Manager</a>
                  <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => navigate("/employeemaster")}>Employees</a>
                  <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => navigate("/skillsetmaster")}>Skillset</a>
                  <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => navigate("/jobpositionmaster")}>Job Position</a>
                  <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => navigate("/recruitmentmanagermaster")}>Recruitment Head</a>
                  <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => navigate("/verticalhead")}>Vertical Head Master</a>
                  <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => navigate("/recruitassigneemaster")}>Recruitment Manager Master</a>
                  <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => navigate("/clientmaster")}>Client</a>
                  <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => navigate("/statusmaster")}>Status</a>
                  <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => navigate("/vendormaster")}>Vendor</a>
                </div>
              </li>
              {
                recruitmentHeadList.some(obj => obj.recruitment_head_emp_id === UserService.getUsername().replace('q', 'Q')) &&
                UserService.isLoggedIn() && location.pathname != '/notification' &&
                <li
                  className={UserService.isLoggedIn() ? "nav-item-active ml-3" : "d-none"}
                >
                  <a className="nav-link" href="/notification">
                  <i className="fas fa-bell"></i> Notification</a>
                </li>
              }
            </ul>
            <ul className="navbar-nav ml-auto">
              <li
                className={
                  UserService.isLoggedIn() ? "nav-item active" : "d-none"
                }
              >
                <Link className="nav-link" to="/">
                  <button className="btn btn-sm  noborder" style={{ color: "black" }} >
                    <i className="fa fa-user" aria-hidden="true"></i> Hi,{" "}
                    {UserService.getFullName()?.split(" ")[0]}
                  </button>
                </Link>
              </li>
              <li
                className={
                  UserService.isLoggedIn() ? "nav-item active" : "d-none"
                }
              >
                <Link className="nav-link" to="/support">
                  <button className="btn btn-sm  noborder" style={{ color: "black" }}>
                    <i className="fa fa-user" aria-hidden="true"></i> Support
                  </button>
                </Link>
              </li>
              <li
                className={
                  UserService.isLoggedIn() ? "d-none" : "nav-item active"
                }
              >
                <Link
                  className="nav-link"
                  onClick={() => {
                    UserService.doLogin({
                      redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
                    })
                  }}
                >
                  <button className="btn btn-sm  noborder" style={{ color: "black" }}>
                    <i className="fas fa-sign-in-alt"></i> Login
                  </button>
                </Link>
              </li>
              <li
                className={
                  UserService.isLoggedIn() ? "d-none" : "nav-item active"
                }
              >
                <Link
                  className="nav-link"
                  onClick={() => UserService.doSignUp()}
                >
                  <button className="btn btn-sm  noborder" style={{ color: "black" }}>
                    <i className="fas fa-user-plus"></i> SignUp
                  </button>
                </Link>
              </li>

              <li
                className={
                  UserService.isLoggedIn() ? "nav-item active" : "d-none"
                }
              >
                <Link
                  className="nav-link"
                  onClick={() => {
                    UserService.doLogout({
                      redirectUri: `${REACT_APP_REDIRECT_URI}`,
                    });
                    // console.log(UserService.getToken());
                  }}
                >
                  <button className="btn btn-sm  noborder" style={{ color: "black" }}>
                    <i className="fas fa-sign-in-alt"></i> Logout
                  </button>
                </Link>
              </li>
            </ul>

          </div>


        </nav>}
    </>
  );
}

export default Navbar;
