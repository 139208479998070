import React, { useState } from 'react'
import quationQ from '../Images/quationQ.png'
import axios, { Axios } from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
const EmployeeLogin = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [errors, setErrors] = useState({});
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [verifyingEmail, setverifyEmail] = useState(false);
  const [verifyingOTP, setverifyOTP] = useState(false);
  const [otpRecieved, setOtpRecieved] = useState(false);
  const navigate = useNavigate()

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const validateEmail = () => {
    if (!email) {
      return "Email is required";
    }
    if (!email.endsWith("@quation.in")) {
      return "Enter the registered Email";
    }
    return "";
  };

  const handleLoginClick = async () => {
    const emailError = validateEmail();
    if (emailError) {
      setErrors({ email: emailError });
    } else {
      setErrors({});
      setverifyEmail(true)
      try {
        const config = {
          method: "post",
          url: `${process.env.REACT_APP_UPLOAD_DATA}/employee/login`,
          headers: {
            Accept: "application/json", // Make sure to set the correct Accept type
            "Content-Type": "application/json",
          },
          data: {
            email: email // Move email here instead of in headers
          }
        };
        const response = await axios(config);
        console.log(response)
        if (response.data.status) {
          toast.success(response.data.message)
          setverifyEmail(false)
          setShowOtpForm(true)
          setOtpRecieved(true)
        }
        else {
          toast.error(response.data.message)
          setverifyEmail(false)
          setEmail('')
        }
      } catch (error) {
        if (error.response) {
          // Check if the error has response data
          if (error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
            setverifyEmail(false)
            setEmail('')
          } else {
            toast.error(error.response.data.message);
            setverifyEmail(false)
            setEmail('')
          }
        } else {
          // If no response, it's a network or unexpected error
          toast.error("Network error! Please check your internet connection.");
          setverifyEmail(false)
          setEmail('')
        }
      }
    }
  };

  const handleVerifyOtpClick = async () => {
    setverifyOTP(true)
    try {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_UPLOAD_DATA}/verifyOtp`,
        headers: {
          Accept: "application/json", // Make sure to set the correct Accept type
          "Content-Type": "application/json",
        },
        data: {
          otp: otp // Move email here instead of in headers
        }
      };
      const response = await axios(config);
      if (response.status == 200) {
        setverifyOTP(false)
        localStorage.setItem('employee_access_token', response.data.employee_acess_token)
        if (localStorage.getItem('jobId')) {
          navigate('/employee/applicationForm')
        }
        else {
          navigate('/employee/dashboard')
        }
      }
    } catch (error) {
      if (error.response.status == 410) {
        toast.error(error.response.data.message)
        setverifyOTP(false)
      }
      if (error.response.status == 404) {
        toast.error(error.response.data.message)
        setverifyOTP(false)
      }
      if (error.response.status == 500) {
        toast.error(error.response.data.message)
        setverifyOTP(false)
      }
    }
  };


  return (
    <div className="container d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
      <div className="card p-4 shadow-sm" style={{ maxWidth: "40rem", width: "100%", borderRadius: "15px"  }}>
        <div className="text-center">
          <div className="text-center mb-4 d-flex align-items-center justify-content-center">
            <img
              src={quationQ}
              alt="Company Logo"
              style={{ width: "60px", height: "60px", cursor:'pointer' }}
              onClick={()=> {
                localStorage.removeItem('access_token')
                localStorage.removeItem('employee_access_token')
                localStorage.removeItem('jobId')
                navigate('/')
              }}
            />
            <h3 style={{ color: "#ff8c00" }}>uation Solution Private Limited</h3>
          </div>
          <hr style={{ borderTop: '2px solid black', margin: '20px 0' }} />
          <h5 className="mb-4" style={{ color: "#FF5722" }}>Employee Login</h5>
        </div>
        <div className="mb-3">
          <label className="form-label">Email</label>
          <input
            type="email"
            className="form-control"
            value={email}
            onChange={handleEmailChange}
            placeholder="Enter your email"
            style={{ borderColor: "#FF5722" }}
            disabled={otpRecieved}
          />
          {errors.email && <div className="text-danger mt-2">{errors.email}</div>}
        </div>
        <button
          className="btn btn-primary w-100 mb-3"
          style={{ backgroundColor: "#FF5722", borderColor: "#FF5722" }}
          onClick={handleLoginClick}
          disabled={otpRecieved}
        >
          {verifyingEmail ? (
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          ) : (
            'Login'
          )}
        </button>

        {showOtpForm && (
          <div>
            <div className="mb-3">
              <label className="form-label">Enter OTP</label>
              <input
                type="text"
                className="form-control"
                maxLength="6"
                value={otp}
                onChange={handleOtpChange}
                placeholder="Enter OTP"
                style={{ borderColor: "#FF5722" }}
              />
            </div>
            <button
              className="btn btn-success w-100 mb-3"
              onClick={handleVerifyOtpClick}
            >
              {verifyingOTP ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              ) : (
                'Verify'
              )}
            </button>
          </div>
        )}
      </div>
    </div >
  );
};

export default EmployeeLogin