import React, { useEffect, useState } from 'react'
import axios from 'axios';
import './RecruitProLandingPageCSS.css'
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const MainContentOfLandingPage = () => {
  const { REACT_APP_UPLOAD_DATA } = process.env
  const [allOngoingJobPostings, SetAllOngoingJobPostings] = useState([])
  const [selectedJob, setSelectedJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleShowDetails = (job) => {
    setSelectedJob(job);  // Set selectedJob to show in the modal
  };
  const handleApply = (job) => {
    setSelectedJob(job);
  };

  const handleCloseModal = () => {
    setSelectedJob(null);
  };

  const fetchOngoingJobPosting = async () => {
    setLoading(true)
    const config = {
      method: "get",
      url: `${REACT_APP_UPLOAD_DATA}/ongoing/jobPosting`,
      headers: {
        Accept: "text/plain",
        "Content-Type": "application/json",
      }
    }
    const getResponse = await axios(config);
    if (getResponse.statusText == 'OK') {
      SetAllOngoingJobPostings(getResponse.data)
      setLoading(false)
    }
    else {
      setLoading(false)
    }

  }
  useEffect(() => {
    fetchOngoingJobPosting()
  }, [])
  return (
    <>
      <div className="job-container container my-5">
        <h1 className="text-center mb-4 section-title text-primary">Job Postings</h1>
        <div className="container">
          {loading ? (
            // Show a loader while fetching the data
            <div className="d-flex justify-content-center mt-5">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : allOngoingJobPostings.length === 0 ? (
            // Show when no job postings are available
            <div className="alert alert-danger text-center mt-5" role="alert">
              Currently, No job openings are available.
            </div>
          ) : (
            // Show job postings when data is available
            <div className="row g-4">
              {allOngoingJobPostings.map((job, index) => (
                <div className="col-md-6 col-lg-4" key={index}>
                  <div className="card job-card shadow-lg h-100 border-0 rounded position-relative">
                    {/* Badge at the top right */}
                    <span
                      className='badge position-absolute top-0 end-0 m-2 bg-success'
                    >
                      {job.current_status == 4 && 'On Going'}
                    </span>

                    <div className="card-body d-flex flex-column">
                      <h5 className="card-title text-primary">{job.job_position}</h5>
                      <p className="card-text mb-2">
                        <strong>Experience:</strong> {job.min_exp}-{job.max_exp} years
                      </p>
                      <p className="card-text mb-2">
                        <strong>Location:</strong> {job.preferred_working_location}
                      </p>
                      <button
                        className="btn btn-outline-primary mt-auto"
                        onClick={() => handleShowDetails(job)}
                      >
                        Read More
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Job Detail Modal */}
      {selectedJob && (
        <div
          className="modal fade show d-block"
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-primary">
                  Apply for {selectedJob.job_position}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseModal}
                ></button>
              </div>
              <div className="modal-body">
                <p><strong>Job Type:</strong> {selectedJob.job_type}</p>
                <p><strong>Mandatory Skills:</strong> {selectedJob.mandatory_skillset}</p>
                <p><strong>Good to Have:</strong> {selectedJob.good_to_have_skillset}</p>
                <p><strong>Description:</strong> {selectedJob.summary}</p>
                <p><strong>Experience:</strong> {selectedJob.min_exp}-{selectedJob.max_exp} years</p>
                <p><strong>Location:</strong> {selectedJob.preferred_working_location}</p>

                {/* Buttons in separate rows */}
                <div className="d-flex flex-column mt-4">
                  <button className="btn btn-success mb-2">
                    Apply as Vendor
                  </button>
                  <button className="btn btn-warning mb-2" onClick={() => {
                    localStorage.setItem("jobId", selectedJob.job_id);
                    navigate('/employee/login')
                  }}>
                    Apply as Employee Referral
                  </button>
                  <button className="btn btn-info" onClick={() => {
                    // Redirect to the login page
                    navigate('/candidate/login')
                    // Save the job_id to localStorage or sessionStorage
                    localStorage.setItem("jobId", selectedJob.job_id);
                    setSelectedJob(null)
                  }}>
                    Apply as Candidate
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


      )}
    </>
  )
}

export default MainContentOfLandingPage